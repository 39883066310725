/* Structure ============================================= */
nav.mainNav {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: var(--Lippincott-Gray-8);
	z-index: 9999;
	padding: 18px 0;
	transition: all 0.3s ease-in-out;
	/* MAX-WIDTH*/
	max-width: 1440px;
	margin: auto;
}

.navWrapper {
	max-width: 1290px;
	margin: 0 auto;
	padding-left: 32px;
	padding-right: 32px;
}

.lippLogo {
	width: 120px;

	&.alt {
		width: 180px;
	}
}

.menuBtn {
	padding: 0;
	margin: 0;
	cursor: pointer;
	padding: 4px 8px;
}
.menuBtn li {
	width: 19px;
	height: 2px;
	border-radius: 2px;
	background-color: white;
	transition: all 0.3s ease-in-out;
}

.menuBtn li:nth-of-type(2) {
	margin: 7px 0;
}

.navContainer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--Lippincott-Gray-8);
	z-index: 9999;
	padding-top: 64px;
	transition: all 0.3s ease-in-out;
}

.navContainer .navWrapper {
	width: 100%;
	height: calc(100% - 64px);
	justify-content: space-around;
}

.navigationMenu {
	max-width: 550px;
	text-align: left;
}
.navigationMenu.small {
	margin-top: 50px;
}
.navigationMenu ul li a,
.navigationMenu ul li button {
	color: white;
	font-family: "Victor Serif Regular";
	font-size: 80px;
	font-weight: 400;
	line-height: 110%;
	letter-spacing: -0.8px;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
}
.navigationMenu.small ul li a,
.navigationMenu.small ul li button {
	color: white;
	font-family: "Victor Serif Regular";
	font-size: 22px;
	font-weight: 400;
	line-height: 100%;
	letter-spacing: -0.8px;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
}

.navigationMenu ul li a:hover,
.navigationMenu ul li button:hover {
	color: var(--Lippincott-Lavender-Medium);
	border-bottom: 1px solid var(--Lippincott-Lavender-Medium);
}
.navigationMenu ul li a.current {
	color: var(--Lippincott-Lavender-Medium);
	font-weight: 200;
	text-decoration: underline;
}
.navigationMenu ul li + li {
	margin-top: 24px;
}
.navigationMenu.small ul li + li {
	margin-top: 16px;
}

.navigationMenu p {
	color: white;
	font-family: "Noto Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	margin: 60px 0 0;
}

.menuArticle {
	max-width: 362px;
	text-align: left;
}

.menuArticle .featuredImage {
	display: block;
	position: relative;
	width: 362px;
	height: 365px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: 40px;
}

.menuArticle .articleLink {
	color: var(--Lippincott-White, #fff);
	font-family: "Victor Serif Regular";
	font-size: 40px;
	font-style: normal;
	font-weight: 500;
	line-height: 130%;
}

.menuArticle .articleLink:hover {
	color: var(--Lippincott-Lavender-Medium);
}

/* Animation =========================================== */
.openNav {
	overflow: hidden;
}
.navContainer {
	transform: translateY(-100%);
}
.openNav .navContainer {
	transform: translateY(0);
}
.openNav .menuBtn li:nth-of-type(1),
.openNav .menuBtn li:nth-of-type(3) {
	width: 36px;
}
.openNav .menuBtn li:nth-of-type(1) {
	transform: rotate(45deg);
}
.openNav .menuBtn li:nth-of-type(2) {
	opacity: 0;
}
.openNav .menuBtn li:nth-of-type(3) {
	transform: rotate(-45deg);
}
.openNav .menuBtn li:nth-of-type(1) {
	transform-origin: 0px;
}
.openNav .menuBtn li:nth-of-type(3) {
	transform-origin: 9px 5px;
}

/* Responsive ================================= */
@media screen and (max-width: 1024px) {
	.navContainer {
		overflow-y: scroll;
		padding-top: 120px;
		padding-bottom: 120px;
		height: auto;
	}
	.navContainer .navWrapper {
		/* height: auto;
        flex-direction: column; */
		gap: 64px;
		width: auto;
	}
	.navigationMenu ul li a,
	.navigationMenu ul li button {
		font-size: 60px;
	}
	.navigationMenu ul li + li {
		margin-top: 32px;
	}
	.navigationMenu p {
		font-size: 14px;
	}
	.menuArticle {
		max-width: 340px;
	}
	.menuArticle .featuredImage {
		width: 100%;
		height: 280px;
	}
}

@media screen and (max-width: 768px) {
	.navigationMenu,
	.menuArticle {
		width: 50%;
	}
	.navigationMenu ul li a,
	.navigationMenu ul li button {
		font-size: 50px;
		line-height: 100%;
	}
	.navigationMenu.small ul li a,
	.navigationMenu.small ul li button {
		font-size: 18px;
		line-height: 0;
	}
	.navigationMenu ul li + li {
		margin-top: 24px;
	}
	.openNav .menuBtn li:nth-of-type(1),
	.openNav .menuBtn li:nth-of-type(3) {
		width: 24px;
	}
	.openNav .menuBtn li:nth-of-type(3) {
		transform-origin: -2px 1px;
	}
	.menuArticle .featuredImage {
		width: 100%;
		height: 240px;
	}
	.menuArticle .articleLink {
		font-size: 24px;
		line-height: 120%;
	}
}

@media screen and (max-width: 480px) {
	.navContainer {
		padding-top: 80px;
		padding-bottom: 60px;
	}
	.navContainer .navWrapper {
		height: auto;
		flex-direction: column;
		gap: 30px;
	}
	.navigationMenu {
		width: 100%;
		max-width: 100%;
	}
	.navigationMenu.small {
		width: 100%;
		max-width: 100%;
		margin-top: 30px;
	}
	.navigationMenu ul li a,
	.navigationMenu ul li button {
		font-size: 50px;
	}
	.navigationMenu ul li + li {
		margin-top: 16px;
	}
	.navigationMenu p {
		font-size: 12px;
		margin: 24px 0 0;
	}
	.menuArticle {
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 20px;
	}
	.menuArticle .featuredImage {
		width: 230px;
		height: 230px;
		margin-bottom: 0;
	}
	.menuArticle .articleLink {
		font-size: 24px;
		max-width: 230px;
		line-height: 120%;
	}
}

@media (min-width: 1024px) {
	.navWrapper {
		padding-left: 20px;
		padding-right: 20px;
	}
}
@media (min-width: 768px) {
	.navWrapper {
		padding-left: 30px;
		padding-right: 30px;
	}
}

@media screen and (max-height: 430px) {
	nav.mainNav {
		padding: 8px 0;
	}
	.navContainer {
		padding-top: 64px;
		padding-bottom: 0;
	}
	.navigationMenu ul li a,
	.navigationMenu ul li button {
		font-size: 24px;
	}
	.navigationMenu ul li + li {
		margin-top: 15px;
	}
	.navigationMenu p {
		font-size: 12px;
		margin: 32px 0 0;
	}
	.menuArticle .featuredImage {
		height: 110px;
	}
	.menuArticle .articleLink {
		font-size: 20px;
	}
}

.articleLine {
	border: none;
	border-top: 0.5px solid black;
	padding: 80px 0 40px;
}
