#root {
	width: 100%;
	max-width: 100%;
	margin: 0 auto;
	text-align: center;
}

:root {
	--homepage-arrow-size: 48px;
}

.section_hero {
	position: relative;
	width: 100%;
	background: #ffffff;
}

.header-players {
	position: relative;
	width: 100%;
	height: 250vh;
}

.players-slider {
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;

	/* INITIAL State */

	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: multiply;

	background: linear-gradient(
			116deg,
			#ffde9f 1.25%,
			#fff2d0 29.54%,
			#ffe3cf 65.02%,
			#ffb7a0 100%
		)
		center cover no-repeat;
}
/* SHADOWS */
.player-shadow {
	position: absolute;
	bottom: 0px;
	left: 25%;
	width: 50%;
	height: 300px;
	opacity: 1;
	z-index: 0;
	transition: all 2s ease-out;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	background-blend-mode: multiply;
	background: linear-gradient(
			116deg,
			#ffde9f 1.25%,
			#fff2d0 29.54%,
			#ffe3cf 65.02%,
			#ffb7a0 100%
		)
		center cover no-repeat;
}

.players-slider_texts {
	position: absolute;
	top: 140px;
	left: 0;
	right: 0;
	text-align: center;
	z-index: 1;
}
.players-slider_title {
	position: relative;
	color: #202020;
	/* Title 1 [Desktop] */
	font-family: "piepie", sans-serif;
	font-size: 400px;
	font-style: normal;
	font-weight: 500;
	line-height: 95%; /* 29.45rem */
}
.players-slider_subtitle {
	color: #202020;
	/* Title 3 [Desktop] */
	margin-block-start: 50px;
	font-family: "Victor Serif Regular", sans-serif;
	font-size: 62px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%; /* 3.75rem */
}

/*/// SPLINE ///*/
.spline_container {
	position: "relative";
	width: "100%";
	height: "100%";
	z-index: 1;
}

.hero-swiper {
	position: sticky;
	top: 0;
	width: 100%;
	height: 100vh;
}
.swiper-slide {
	position: relative;
	display: flex !important;
	align-items: center;
	justify-content: center;
}

.swiper-slide-active {
	opacity: 1;
	scale: 1;
}
.player-background-off {
	background-position-x: 60%;
}

/*/// NAVIGATION & PROMT ///*/
.navigation {
	position: absolute;
	bottom: 10%;
	z-index: 10;
	left: 0;
	right: 0;
	margin: auto;
	width: 80%;
}

.promt-title {
	font-family: "Victor Serif Regular", sans-serif;
	font-weight: 500;
	font-size: 30px;
	line-height: 30px;
	text-align: center;
}
.prompt {
	font-family: "Noto Sans";
	font-size: 1.6rem;
}
.navigation_next {
	width: 3.2rem;
	height: 3.2rem;
	background: url(assets/nav-next.svg) no-repeat center center / 40%;
	cursor: pointer;
	transition: all 0.3s ease-out;
}
.navigation_prev {
	width: 3.2rem;
	height: 3.2rem;
	background: url(assets/nav-prev.svg) no-repeat center center / 40%;
	cursor: pointer;
	transition: all 0.3s ease-out;
}

.navigation_next:hover,
.navigation_prev:hover {
	background-size: 55% !important;
}

.navigation_select {
	display: inline-flex;
	padding: 1.6rem 3.2rem;
	justify-content: center;
	align-items: center;
	gap: 1.6rem;
	border-radius: 0.8rem;
	border: 1px solid #000;
	background: rgba(255, 255, 255, 0.5);
	color: #000;
	font-family: "Noto Sans", sans-serif;
	font-size: 2rem;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: uppercase;
	margin: 0 3.2rem;
	cursor: pointer;
}

/* CONTENT */
.section-player_hero {
	position: relative;
	width: 100%;
	height: 100vh;
	background: url("assets/backs/back_1.jpg");
	background-position: center;
	background-size: cover;
}

/* MOBILE */
@media only screen and (max-width: 768px) {
	.promt-title {
		font-size: 20px;
		line-height: 150%;
		margin: auto;
		max-width: 240px;
	}
	.player-shadow {
		bottom: 30%;
		height: 140px;
		width: 100%;
		left: 0px;
	}
	.players-slider_title {
		font-size: 152px;
	}
	.players-slider_subtitle {
		font-size: 32px;
	}
	.navigation {
		width: 100%;
	}

	/* PLAYERS */
	.swiper-slide-active div:after {
		width: 100%;
		height: 80px;
		bottom: 24%;
		opacity: 0.3;
	}

	.navigation_select {
		font-size: 16px;
	}

	/* NAVIGATION */
	.image-swiper-button-next {
		bottom: calc(10% - 15px);
		top: unset;
	}
}

canvas {
	margin: auto;
}

/* TABLETS */
@media (min-width: 769px) and (max-width: 1024px) {
	.players-slider_title {
		font-size: 300px;
	}
	.players-slider_subtitle {
		font-size: 46px;
		margin-top: 40px;
	}
}

@media only screen and (max-width: 480px) {
	.players-slider_title {
		margin-top: 100px;
	}
	.players-slider_subtitle {
		font-size: 36px;
		margin-top: 120px;
	}
}
.
/* index.css */
a {
	font-weight: 500;
	color: #646cff;
	text-decoration: inherit;
}

a:hover {
	color: #535bf2;
}

body {
	margin: 0;
	display: flex;
	place-items: center;
	min-width: 320px;
	min-height: 100vh;
}

.container {
	position: relative;
	max-width: 1440px;
	margin: 0px auto;
}

.icons-menu {
	position: fixed;
	bottom: 26px;
	right: 39px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.5s ease-out;
	z-index: 3;
}

.icons-menu.is-visible {
	opacity: 1;
	visibility: visible;
	transition: all 0.5s ease-out;
}

.icons-menu ul {
	list-style: none;
	padding: 0px;
	visibility: hidden;
	height: 0px;
	transition: all 0.2s ease-out;
}

.icons-menu ul li {
	width: 0px;
	height: 0px;
	border-radius: 50%;
	border: 1px solid var(--Black, #202020);
	background: var(--White, #fff);
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
	margin: 24px auto;
	visibility: hidden;
	transition: all 0.2s ease-out;
}

.icons-menu ul li:hover {
	border: 1px solid #6f6f6f;
	background-color: #f4f4f4 !important;
}

.icons-menu ul.is-active {
	visibility: visible;
	height: 430px;
	transition: all 0.2s ease-out;
	margin: 0px;
}

.icons-menu ul.is-active li {
	width: 60px;
	height: 60px;
	visibility: visible;
}

.icons-menu .item-selected {
	width: 104px;
	height: 104px;
	border-radius: 50%;
	border: 1px solid var(--Black, #202020);
	box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}

.icons-menu ul li:hover,
.icons-menu .item-selected:hover {
	cursor: pointer;
}

.swiper-slide {
	cursor: pointer;
}

.image-swiper-button-prev,
.image-swiper-button-next {
	position: absolute;
	top: calc(50% + var(--homepage-arrow-size) / 2);
	z-index: 999;
	cursor: pointer;
	width: var(--homepage-arrow-size);
	height: var(--homepage-arrow-size);
}

.image-swiper-button-prev {
	left: 100px;
	background: url(assets/prev.png) no-repeat center center /
		var(--homepage-arrow-size);
	transition: all 0.3s ease;
}

.image-swiper-button-next {
	right: 100px;
	background: url(assets/next.png) no-repeat center center /
		var(--homepage-arrow-size);
	transition: all 0.3s ease;
}

.image-swiper-button-prev:hover,
.image-swiper-button-next:hover {
	transform: scale(1.1);
}

.swiper-slide a#logo {
	display: none !important;
}

.canvas-wrapper {
	height: 100%;
}

@media screen and (max-width: 960px) {
	.canvas-wrapper {
		height: 70%;
	}
	/* .canvas-wrapper>div>div {
    scale: 0.7;
  }
  .canvas-wrapper>div {
    width: 200% !important;
    height: 150% !important;
    position: relative;
    left: -50%;
    top: -80px;
  } */
	/* .canvas-wrapper>div>div>canvas{
    transform: scale(0.7);
  }
  .canvas-wrapper>div>div{
    width: 200% !important;
    position: relative;
    left: -50%;
  } */

	.image-swiper-button-prev,
	.image-swiper-button-next {
		width: 90px;
	}

	.image-swiper-button-prev {
		left: 15px;
		background-size: 80px !important;
	}

	.image-swiper-button-next {
		right: 15px;
		background-size: 80px !important;
	}

	.image-swiper-button-prev:hover,
	.image-swiper-button-next:hover {
		background-size: 90px;
	}
}
@media all and (max-width: 768px) {
	.image-swiper-button-prev,
	.image-swiper-button-next {
		top: unset;
		bottom: calc(16% - 16px);
	}

	.players-slider_subtitle {
		margin-top: 280px;
	}

	.navigation {
		bottom: 10%;
	}
}
@media screen and (max-width: 480px) {
	.canvas-wrapper {
		height: 60%;
	}

	/* .canvas-wrapper>div>div {
    scale: 0.6;
  } */

	/* .canvas-wrapper>div {
    width: 300% !important;
    height: 150% !important;
    position: relative;
    left: -100%;
    top: -80px;
  } */

	.image-swiper-button-prev {
		left: 10px;
		background-size: 48px !important;
		background-position: left center !important;
	}

	.image-swiper-button-next {
		right: 10px;
		background-size: 48px !important;
		background-position: right center !important;
	}

	.canvas-wrapper,
	.spline_container {
		pointer-events: none;
	}

	.players-slider_subtitle {
		margin-top: 120px;
	}
}

@media screen and (max-height: 430px) {
	.bgLsRT {
		top: 44px !important;
		height: 44px !important;
	}
	.laBXkU {
		max-width: 160px !important;
	}
	.navigation {
		bottom: 0px;
	}
	.promt-title {
		margin: 0;
		font-size: 20px;
		line-height: 20px;
	}
	.prompt {
		font-size: 12px;
	}
	.image-swiper-button-prev,
	.image-swiper-button-next {
		top: auto;
		bottom: 15px;
		width: 68px;
		height: 40px;
		background-size: 52px;
	}
	.image-swiper-button-prev {
		left: 20px;
	}
	.image-swiper-button-next {
		right: 20px;
	}
	.players-slider_texts {
		top: 110px;
	}
	.players-slider_title {
		font-size: 240px;
	}
	.players-slider_subtitle {
		font-size: 33px;
		margin-top: -70px;
	}
}
