:root {
	font-size: 62.5%;
	--black: #202020;
	--dark-gray: #6f6f6f;
	--pink: #e8ddff;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* Standard syntax */

	/* --oncourse-blue: #0364D8;
  --support-yellow: #FFE36C;
  --background-gray: #F8F8F8;
  --green: #A6CB95;
  --support-purple: #0D1680; */
	min-width: 390px;
}

/* canvas {
  width: 100% !important;
  height: 100% !important;
} */

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	scroll-behavior: smooth;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

mark {
	background-color: var(--pink);
}

li,
ul {
	list-style: none;
	padding: 0;
}

a {
	cursor: pointer;
}

@font-face {
	font-family: "Victor Serif Regular";
	src: url("fonts/VictorSerif-40Regular.otf");
}
