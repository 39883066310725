@import url("https://use.typekit.net/uef8bhk.css");
:root {
  --naming-color-black: #191919;
  --naming-color-other-black: #202020;
  --naming-color-white: white;
  --naming-font-body: "Noto Sans", sans-serif;
  --naming-font-heading: "piepie", "Helvetica Neue Light", "Helvetica Neue",
  	"Helvetica", "Arial", sans-serif;
  --naming-font-alt-heading: "Victor Serif Regular", sans-serif;
  --naming-font-roobert: "Roobert PRO";
}

@font-face {
  font-family: "piepie", sans-serif;
  font-weight: 400;
  font-style: normal;
}
u {
  text-underline-offset: 2px;
  text-decoration-thickness: 1px;
}

@font-face {
  font-family: "Victor Serif";
  src: url(../fonts/VictorSerif-40Regular.woff) format("woff"), url(../fonts/VictorSerif-40Regular.woff2) format("woff2"), url(../fonts/VictorSerif-40Regular.otf) format("otf");
  font-weight: 400;
}
body {
  background: var(--naming-color-black);
  margin: 0;
}
body .wrapper {
  max-width: 1440px;
  margin: 0 auto;
}

.light-mode h1,
.light-mode h1 .eyebrow,
.light-mode .left h1,
.light-mode .info .breadcrumb,
.light-mode .left .info .tagging a,
.light-mode .info .tagging ul li a,
.light-mode .info .tagging ul li,
.light-mode .info .tagging a,
.light-mode .title {
  color: var(--naming-color-white) !important;
}

.curtain {
  position: relative;
  z-index: 0;
}
.curtain::after {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
}

.exploringCard.curtain::after {
  border-radius: 20px;
}

.hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  height: calc(100dvh - 64px);
  padding-block-start: 64px;
  background-color: var(--naming-color-black);
  position: relative;
  z-index: 0;
}
.hero .eyebrow {
  color: #020202;
  font-family: var(--naming-font-roobert);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%; /* 36px */
  letter-spacing: -0.4px;
  text-transform: uppercase;
  /* 
  @media (max-height: 1100px) and (min-width: $mobile) {
  	font-size: 35px;
  }
  @media (max-height: 1100px) and (min-width: $mobile) {
  	font-size: 25px;
  } */
}
.hero:before {
  position: absolute;
  content: "";
  background-color: black;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.25;
  mix-blend-mode: lighten;
}
@media ((max-height: 920px) and (min-width: 992px)) {
  .hero:before {
    opacity: 0.1;
  }
}
@media (max-width: 992px) {
  .hero:before {
    left: 0;
  }
}
@media (max-width: 992px) {
  .hero {
    display: block;
    height: auto;
  }
}
.hero .left {
  background: transparent url(../images/naming/header-new.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.hero .left.ai-culture {
  background-image: url(../images/ai-culture/ai-culture-header.jpg);
}
.hero .left.listening-live {
  background-image: url(../images/listening-live/article2_header_comp.jpg);
}
.hero .left.listening {
  background-image: url(../images/listening/article3_header_comp.jpg);
}
.hero .left.replicating {
  background-image: url(../images/replicating/gundy1479_A_futuristic_library_with_many_books_and_crowded_peop_0767a76b-ceab-4013-a86e-d1b39b48721b\ 1.png);
}
.hero .left.lilah {
  background-image: url(../images/lilah/gundy1479_A_futuristic_library_with_many_people_walking_around__dffc0ba9-530f-420d-9b8b-22466e9f083e\ 1.jpg);
}
.hero .left .info {
  color: var(--naming-color-white);
  padding-inline-start: 10.2%;
  bottom: 0;
  position: absolute;
  padding-block-end: 15px;
}
.hero .left .info .breadcrumb {
  font-family: var(--naming-font-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--naming-color-black);
  text-align: left;
}
.hero .left .info .breadcrumb a {
  color: inherit;
  text-decoration: none;
}
.hero .left .info .tagging {
  padding: 0;
  font-family: var(--naming-font-body);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--naming-color-black);
}
.hero .left .info .tagging ul {
  margin: 0;
  margin-block-start: 6px;
  padding: 0;
  list-style-type: none;
}
.hero .left .info .tagging ul li {
  display: inline-block;
  margin-inline-end: 5px;
  color: var(--naming-color-black);
}
.hero .left .info .tagging ul li a {
  color: inherit;
  text-decoration: none;
}
.hero .right {
  position: relative;
  color: var(--naming-color-white);
  display: flex;
  flex-direction: column;
  font-family: var(--naming-font-body);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  justify-content: center;
  text-align: left;
}
@media (max-height: 1100px) and (min-width: 992px) {
  .hero .right {
    font-size: 16px;
  }
}
.hero .right section {
  padding-inline: 12%;
  position: absolute;
  /* NO CAPS */
  /*
  & p:not(.no-cap):not(.initial-cap):not(.standout-cap):first-of-type {
  	&::first-letter {
  		font-family: var(--naming-font-alt-heading);
  		font-style: normal;
  		line-height: 1;
  		color: var(--naming-color-white);
  		padding: 0 0 0 0;
  		margin-right: 6px;
  		float: left;
  		font-size: 300%;
  		margin: 0 0 -0.3em 0;
  	}
  }

  & p.initial-cap:not(.no-cap) {
  	&::first-letter {
  		font-family: var(--naming-font-alt-headingg);
  		font-style: normal;
  		line-height: 1;
  		color: var(--naming-color-white);
  		padding: 6px 0 0 0;
  		margin-right: 2px;
  		float: none;

  		font-size: 300%;
  	}
  }
  & p.standout-cap:not(.no-cap) {
  	&::first-letter {
  		font-family: var(--naming-font-alt-heading);
  		font-style: normal;
  		line-height: 1;
  		color: var(--naming-color-white);
  		padding: 6px 3px 0 0;
  		margin-right: 6px;
  		float: none;

  		font-size: 300%;
  		// margin: 0 0 -0.3em 0;
  	}
  }

  */
}
@media (max-width: 992px) or ((max-height: 920px) and (min-width: 992px)) {
  .hero .right section {
    position: relative;
    margin-block: 2em 4em;
  }
}
.hero .right section p {
  font-family: inherit;
  font-size: 18px;
}
.hero h1 {
  color: #020202;
  font-family: var(--naming-font-alt-heading);
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  letter-spacing: -1px;
  width: 68.3%;
  padding-inline-start: 10.2%;
  text-align: left;
}
@media (max-width: 1450px) {
  .hero h1 {
    font-size: 80px;
  }
}
@media (max-width: 992px) {
  .hero h1 {
    font-size: 60px;
    margin-block: 2em;
  }
}
@media (max-height: 1100px) and (min-width: 992px) {
  .hero h1 {
    font-size: 75px;
  }
}
@media (max-height: 1100px) and (min-width: 992px) {
  .hero h1 {
    font-size: 55px;
  }
}
.hero--full {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0;
  height: calc(100dvh - 64px);
  padding-block-start: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background-color: var(--naming-color-black);
  position: relative;
  z-index: 0;
}
.hero--full h1 {
  color: #020202;
  font-family: var(--naming-font-alt-heading);
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%; /* 90px */
  letter-spacing: -1px;
  width: 68.3%;
  padding-inline-start: 10.2%;
  text-align: left;
}
@media (max-width: 1450px) {
  .hero--full h1 {
    font-size: 80px;
  }
}
@media (max-width: 992px) {
  .hero--full h1 {
    font-size: 60px;
    margin-block: 2em;
  }
}
.hero--full .info {
  color: var(--naming-color-white);
  padding-inline-start: 10.2%;
  bottom: 0;
  position: absolute;
  padding-block-end: 15px;
}
.hero--full .info .breadcrumb {
  font-family: var(--naming-font-body);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 19.2px */
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--naming-color-black);
  text-align: left;
}
.hero--full .info .breadcrumb a {
  color: inherit;
  text-decoration: none;
}
.hero--full .info .tagging {
  padding: 0;
  font-family: var(--naming-font-body);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--naming-color-black);
}
.hero--full .info .tagging ul {
  margin: 0;
  margin-block-start: 6px;
  padding: 0;
  list-style-type: none;
}
.hero--full .info .tagging ul li {
  display: inline-block;
  color: inherit;
}
.hero--full .info .tagging ul li a {
  color: inherit;
  text-decoration: none;
  margin-inline-end: 4px;
}
.hero--full .title {
  position: absolute;
  top: 0;
  right: 30px;
  color: var(--naming-color-white);
  font-family: var(--naming-font-heading);
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 90%;
  text-transform: uppercase;
  margin-block: 80px 0;
}
@media (max-width: 1100px) or (max-height: 920px) {
  .hero--full .title {
    font-size: 65px;
    margin-block: 30px 0;
  }
}
@media (max-width: 992px) and (max-height: 920px) {
  .hero--full .title {
    font-size: 45px;
    margin-block: 30px 0;
    top: 45px;
  }
}
@media (max-width: 992px) {
  .hero--full .title {
    position: static;
    margin: 30px 30px 0 0;
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
    flex-direction: column;
  }
}
@media (min-width: 992px) and (max-height: 650px) {
  .hero--full .title {
    display: none;
  }
}
@media (max-height: 780px) and (min-width: 992px) {
  .hero--full .title {
    display: none;
  }
}
.hero--full .title span {
  display: block;
  line-height: 1;
  text-align: center;
  text-transform: none;
  font-size: 14px;
  color: var(--naming-color-white);
  font-family: "Victor Serif";
  font-style: normal;
  font-weight: 500;
}
.hero--full.humans {
  background: transparent url(../images/humans/article1_header_comp.jpg) center center no-repeat;
  background-size: cover;
}

.scrollingsections {
  position: relative;
  max-width: 1440px;
  overflow: hidden;
}

.baseline {
  background: url(../images/naming/baseline.jpg) top left no-repeat;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 96px;
  color: var(--naming-color-white);
  position: relative;
  background-size: cover;
  height: 100vh;
  grid-auto-rows: 1fr;
}
@media (max-width: 992px) {
  .baseline {
    height: auto;
  }
}
.baseline.baseline2 {
  background: url(../images/naming/baseline2.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 992px) {
  .baseline.baseline2 {
    height: auto;
  }
}
.baseline.baseline3 {
  background: url(../images/naming/baseline3.jpg) top left no-repeat;
  background-size: cover;
}
@media (max-width: 992px) {
  .baseline.baseline3 {
    height: auto;
  }
}
@media (max-width: 992px) {
  .baseline {
    display: block;
    height: auto;
    padding: 2em;
  }
}
.baseline .left {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-inline: 60px;
  -webkit-align-items: center;
  color: var(--naming-color-white);
}
@media (max-width: 1200px) {
  .baseline .left {
    padding-inline: 30px;
    margin-block-end: 30px;
  }
}
.baseline .left .section-title {
  color: var(--naming-color-white);
  margin: 0;
  padding: 0;
  font-family: var(--naming-font-alt-heading);
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 100px */
  text-transform: uppercase;
  text-align: center;
}
.baseline .left .section-title span {
  display: block;
  margin-block: -10px;
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  font-family: var(--naming-font-alt-heading);
  font-size: 500px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 500px */
}
@media (max-width: 1450px) {
  .baseline .left .section-title {
    font-size: 90px;
  }
  .baseline .left .section-title span {
    font-size: 400px;
  }
}
@media (max-width: 1200px) or ((max-height: 920px) and (min-width: 992px)) {
  .baseline .left .section-title {
    font-size: 65px;
  }
  .baseline .left .section-title span {
    font-size: 300px;
  }
}
@media (max-width: 992px) {
  .baseline .left .section-title {
    font-size: 55px;
    padding-inline-end: 32px;
  }
  .baseline .left .section-title span {
    font-size: 250px;
  }
}
.baseline .right {
  color: var(--naming-color-white);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding-inline-end: 85px;
  text-align: left;
}
.baseline .right .section-title {
  font-family: var(--naming-font-alt-heading);
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 60px */
  margin: 0;
  padding: 0;
}
.baseline .right p {
  color: var(--naming-color-white);
  font-family: var(--naming-font-body);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 992px) or ((max-height: 920px) and (min-width: 992px)) {
  .baseline .right {
    padding-inline-end: 20px;
  }
  .baseline .right .section-title {
    font-size: 35px;
  }
}
@media (min-width: 992px) and (max-width: 1440px) {
  .baseline .right {
    padding-inline-end: 110px;
  }
}

.scroll-element {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
  background: #33353e;
}
.scroll-element iframe,
.scroll-element video {
  width: 100%;
  padding: 0;
  margin: 0;
  object-fit: cover;
  z-index: 1000;
  left: auto !important;
}
@media (max-width: 992px) {
  .scroll-element iframe,
  .scroll-element video {
    max-height: fit-content;
  }
}
.scroll-element iframe[poster],
.scroll-element video[poster] {
  object-fit: fill;
  object-fit: contain;
  width: 100%;
}
@media (max-width: 375px) {
  .scroll-element iframe[poster],
  .scroll-element video[poster] {
    width: calc(100% - 30px);
  }
}
.scroll-element iframe[poster][data-height="405"],
.scroll-element video[poster][data-height="405"] {
  height: 405px;
}
.scroll-element iframe[poster][data-height="335"],
.scroll-element video[poster][data-height="335"] {
  height: 335px;
}
.scroll-element section.video {
  position: relative;
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.scroll-element section.video img {
  width: 100%;
}
.scroll-element section.video img.narrower {
  width: 70%;
}
@media (max-width: 992px) {
  .scroll-element section.video {
    top: auto;
    transform: none;
  }
}
.scroll-element.top-aligned section.video {
  top: 75px;
  transform: none;
}
@media (max-width: 992px) {
  .scroll-element {
    display: block;
  }
}
.scroll-element .image-section {
  margin: 0;
  padding: 0;
  width: 100%;
  display: none;
}
.scroll-element .image-section img {
  width: 100%;
  object-fit: contain;
}
.scroll-element .left {
  padding-block-start: 0;
  background: transparent;
  transform: none !important;
}
.scroll-element .left .mediawrapper {
  height: 100dvh;
  position: relative;
}
@media (max-width: 992px) {
  .scroll-element .left {
    display: none;
  }
}
.scroll-element .right {
  padding: 96px 71px;
  text-align: left;
  background: linear-gradient(116deg, #d2f1ff 1.25%, #eaf9ff 40.16%, #fff4de 77.71%, #ffe0ca 100%);
  color: var(--naming-color-other-black);
  font-family: var(--naming-font-body);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
@media (max-width: 992px) {
  .scroll-element .right {
    padding-inline: 32px;
    padding-block: 32px;
  }
}
@media (max-width: 375px) {
  .scroll-element .right {
    padding-inline: 32px 45px;
  }
}
.scroll-element .right img {
  width: 100%;
  display: block;
}
.scroll-element .right iframe,
.scroll-element .right video,
.scroll-element .right .image-section {
  display: none;
}
@media (max-width: 992px) {
  .scroll-element .right iframe,
  .scroll-element .right video,
  .scroll-element .right .image-section {
    display: block;
  }
}
.scroll-element .right .video {
  position: relative;
  margin: 100px 0;
  z-index: 1;
}
@media (min-width: 992px) {
  .scroll-element .right .video {
    display: none;
  }
}
@media (max-width: 992px) {
  .scroll-element .right .video {
    margin: 50px 0;
  }
}
.scroll-element .right.third {
  padding-block: 130px;
  fill: linear-gradient(116deg, #d2f1ff 1.25%, #eaf9ff 40.16%, #fff4de 77.71%, #ffe0ca 100%);
  background-blend-mode: multiply;
}
.scroll-element .right .section-headline {
  color: var(--naming-color-other-black);
  text-wrap: balance;
  font-family: var(--naming-font-alt-heading);
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 95%;
  margin: 0 0 42px 0;
  padding: 0;
}
@media (max-width: 992px) {
  .scroll-element .right .section-headline {
    width: auto;
  }
}
.scroll-element .right .section-heading-larger, .scroll-element .right .section-subhead-larger {
  color: var(--naming-color-other-black);
  font-family: var(--naming-font-alt-heading);
  font-size: 60px;
  font-style: normal;
  font-weight: 500;
  line-height: 95%;
  text-wrap: balance;
}
@media (max-width: 992px) {
  .scroll-element .right .section-heading-larger, .scroll-element .right .section-subhead-larger {
    width: auto;
  }
}
.scroll-element .right ul,
.scroll-element .right ol {
  list-style-type: none;
  padding-inline-start: 0;
  margin-block-start: 0;
}
.scroll-element .right ul li,
.scroll-element .right ol li {
  margin-block-end: 1.5em;
}
@media (max-width: 992px) {
  .scroll-element .right ul li,
  .scroll-element .right ol li {
    margin-block-end: 0.75em;
  }
}
.scroll-element .right ul.list-style,
.scroll-element .right ol.list-style {
  list-style-type: none;
  padding-inline-start: 30px;
  margin-block-start: initial;
}
.scroll-element .right ul.list-style li,
.scroll-element .right ol.list-style li {
  list-style: initial;
}
.scroll-element .right .section-subhead {
  color: var(--naming-color-other-black);
  font-family: var(--naming-font-alt-heading);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%; /* 33px */
  margin: 0;
  padding: 0;
  margin-block-end: 1em;
}
.scroll-element .right .section-subhead-smaller {
  color: var(--naming-color-other-black);
  text-align: center;
  font-family: var(--naming-font-alt-heading);
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.scroll-element .right .section-subhead .centered {
  text-align: center;
}
@media (max-width: 992px) {
  .scroll-element .right .section-subhead {
    max-width: none;
  }
}
.scroll-element .right .section-subhead.special {
  margin-block-start: 70px;
}
.scroll-element .right .section-subhead.special.lesson + ul li {
  margin-bottom: 1.5em;
}
.scroll-element .right .block {
  color: var(--naming-color-other-black);
  font-family: var(--naming-font-alt-heading);
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 992px) {
  .scroll-element .right .block {
    font-size: 30px;
  }
}
.scroll-element .right .origination {
  margin-block: 70px;
}
.scroll-element .right .tagging {
  list-style-type: none;
  padding-inline-start: 0;
  font-style: italic;
}
.scroll-element .right .tagging li {
  margin-block-end: 0;
}

.hide {
  display: none;
}

p.initial-cap::first-letter {
  font-family: var(--naming-font-alt-heading);
  font-style: normal;
  line-height: 1;
  color: currentColor;
  padding: 6px 0 0 0;
  margin: 0;
  float: none;
  font-size: 300%;
}

p.drop-cap::first-letter {
  font-family: var(--naming-font-alt-heading);
  font-style: normal;
  line-height: 1;
  color: currentColor;
  padding: 0 3px 0 0;
  margin-right: 6px;
  float: left;
  font-size: 300%;
  margin: 0 0 -0.3em 0;
}

p.standout-cap::first-letter {
  font-family: var(--naming-font-alt-heading);
  font-style: normal;
  line-height: 0.5;
  color: currentColor;
  padding: 6px 3px 0 0;
  margin-right: 0px;
  margin-left: -0.6em;
  float: none;
  font-size: 3em;
}

code {
  border-radius: 19px;
  border: 1.5px solid #000;
  background: #fff;
  display: block;
  padding: 21px 15px;
  color: var(--naming-color-other-black);
  font-family: var(--naming-font-body);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

