.loader-container{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 10000;

    display: flex;
}
.loader-bar{
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    width: 2px;
    height: 0vh;
    background: #D8DEFF;
    z-index: 1;
}
.loader-courtain_left{
    position: relative;
    width: 50%;
    height: 100vh;
    background-color: #202020;
}
.loader-courtain_right{
    position: relative;
    width: 50%;
    height: 100vh;
    background-color: #202020;
}