/* GetInTouch ======================= */
.getInTouch {
	padding-top: 124px;
	padding-bottom: 420px;
	position: relative;

	background-size: cover;
	background-position: center;
}
.getInTouch::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(
			139deg,
			rgba(0, 0, 0, 0.5) 12.93%,
			rgba(0, 0, 0, 0) 56.86%
		),
		rgba(0, 0, 0, 0.65);
	z-index: 1;
}

.getInTouchButton {
	position: relative;
	z-index: 100;
	cursor: pointer;
	padding-top: 40px;
}

.getInTouchButton > div {
	display: inline-flex;
	align-items: center;
	border-bottom: 2px solid var(--Lippincott-Lavender-Medium);
}

.getInTouchButton h3 {
	color: var(--Lippincott-Lavender-Medium);
	font-family: "Victor Serif Regular";
	font-size: 80px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
	letter-spacing: -0.812px;
	margin: 0 16px 0 0;
}
.getInTouchButton svg {
	position: relative;
	top: 10px;
}

/* Form ====================================== */

.touchForm {
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: 1440px;
	background-color: var(--Lippincott-Black);
	z-index: 1000;
	padding: 40px 0 80px;

	/* animation ================ */
	transform: translateY(120%);
	transition: all ease-in-out 0.4s;
}

.openGetInTouch {
}

.openGetInTouch .touchForm {
	transform: translateY(0);
}

.closeForm {
	position: absolute;
	top: 30px;
	right: 30px;
	cursor: pointer;
}

.talkAI {
	max-width: 530px;
	text-align: left;
}

.talkAI h3 {
	color: white;
	font-family: "Victor Serif Regular";
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 125%;
	margin: 0 0 60px;
	text-align: left;
}

.thanks {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	width: 100%;
}

.thanks h3 {
	color: white;
	font-family: "piepie", sans-serif;
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 125%;
	margin: 0;
	text-align: center;
}
.thanks h4 {
	color: white;
	font-family: "piepie", sans-serif;
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: 125%;
	margin: 0;
	text-align: center;
}

.thanks button {
	align-self: flex-end;
}

.thanks .mt-100 {
	margin-top: 100px;
}
.thanks .mt-60 {
	margin-top: 60px;
}

.dropdownTouch label {
	color: white;
	font-family: "Noto Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.dropdownTouch label svg {
	margin-right: 4px;
}

.dropdownTouch select {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;

	/* Text ================================ */
	color: var(--Lippincott-Lavender-Medium);
	font-family: "Victor Serif Regular";
	font-size: 30px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;

	background-color: transparent;
	border: 0;
	border-bottom: 1px solid white;
	border-radius: 0;

	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE2IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03Ljk2NzQgOC43NjQ2NUwwLjIwMDc2MyAwLjExNjc5NkwxNS43MzQgMC4xMTY3OTVMNy45Njc0IDguNzY0NjVaIiBmaWxsPSJ3aGl0ZSIvPgo8L3N2Zz4K"); /* Tu imagen en base64 */
	background-repeat: no-repeat;
	background-position: right 8px center;
	padding: 8px 24px 6px 0;
}

.dropdownTouch select:hover {
	background-color: transparent;
}

.dropdownTouch select:focus {
	outline: none;
}

.talkAI > .dropdownTouch:first-of-type {
	margin-bottom: 130px;
}

.talkAIform {
	max-width: 532px;
}

/* HTML contact Form styled is in Variable And Structure.css */

@media screen and (max-width: 1024px) {
	.getInTouchFormContainer {
		gap: 64px;
		flex-direction: column;
		align-items: center !important;
	}
	.touchForm {
		top: 0;
		height: auto;
		overflow-y: scroll;
	}
	.talkAI > .dropdownTouch:first-of-type {
		margin-bottom: 64px;
	}
	.getInTouchButton h3 {
		font-size: 64px;
	}

	.thanks button {
		align-self: center;
	}

	.getInTouchButton svg {
		width: 30px;
		height: 30px;
	}
}

@media screen and (max-width: 768px) {
	.talkAI {
		max-width: 100%;
	}
	.getInTouch {
		padding-top: 33vh;
		padding-bottom: 33vh;
	}
	.getInTouchButton h3 {
		font-size: 48px;
	}
	.touchForm .footerWrapper {
		padding-left: 16px;
		padding-right: 16px;
	}
	.talkAIform {
		margin-top: 48px;
		max-width: 100%;
	}
	.talkAI h3 {
		font-size: 32px;
	}
	.touchForm {
		padding: 64px 0 80px;
	}
	.closeForm {
		top: 16px;
		right: 16px;
		transform: scale(0.6);
	}
	.getInTouchButton svg {
		width: 25px;
		height: 25px;
		top: 5px;
	}
}
@media screen and (max-width: 480px) {
	.getInTouch {
		padding-top: 172px;
		padding-bottom: 172px;
	}
	.getInTouchButton {
		padding-top: 0;
	}
	.getInTouchButton > div {
		border: 0;
	}
	.getInTouchButton h3 {
		font-size: 36px;
		text-align: left;
		border-bottom: 2px solid var(--Lippincott-Lavender-Medium);
	}

	.getInTouchButton svg {
		width: 20px;
		height: 20px;
		top: 3px;
	}
	.getInTouchButton h3 {
		margin-right: 8px;
	}
}
@media screen and (max-width: 480px) {
	.getInTouchButton h3 {
		font-size: 32px;
	}
}
