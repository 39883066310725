.regenerateContainer {
    min-height: 150vh;
    position: sticky;
    top: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 0;
}

.regenerateContentWrapper {
    min-height: calc(100vh - 64px);
    /* top: 64px; */
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.regenerateText {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 64px;
    font-size: 40px;
}
.regenerateMenu {
    font-family: "noto-sans-display-semiconden", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    padding: 0 20px;
    margin-top: auto;
    margin-bottom: 100px;
}

@media screen and (max-width: 1024px) {
    .regenerateContainer {
        min-height: 150vh;
    }
}

@media screen and (max-width: 480px) {
    .regenerateContainer {
        top: 0;
    }
    .regenerateMenu {
        margin-bottom: 0;
    }
}