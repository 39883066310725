/* Defining colors and variables ================== */
:root {
	--light-gray: #252525;
	--Lippincott-Gray-4: #adadad;
	--Lippincott-Gray-5: #8f8f8f;
	--Lippincott-Gray-7: #252525;
	--Lippincott-Gray-8: #202020;
	--Lippincott-Gray-10: #111;
	--Lippincott-Black: #191919;
	--Lippincott-Lavender-Medium: #ba9cff;
}

@font-face {
	font-family: "Roobert PRO";
	src: url("../../fonts/RoobertPRO-Medium.otf") format("opentype");
}

/* Defining structure ============================= */
.dFlex {
	display: flex;
}
.spaceBetween {
	justify-content: space-between;
}
.alignItemsCenter {
	align-items: center;
}
.alignItemsStart {
	align-items: flex-start;
}
.flexColumn {
	flex-direction: column;
}

.footerWrapper {
	max-width: 1290px;
	margin: 0 auto;
	padding-left: 32px;
	padding-right: 32px;
}

/* Footer forms ==================================== */
/* Global styles */
.footerForm label {
	color: white;
	font-family: "Noto Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.footerForm input {
	background: transparent;
	border: 0;
	padding: 10px 0;
	width: 100%;
	color: white;
	font-family: "Victor Serif Regular";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 33px;
}

.footerForm input::placeholder {
	color: var(--Lippincott-Gray-4);
	font-family: "Victor Serif Regular";
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 33px;
}

.footerForm input:focus-visible {
	outline: 0;
}

.footerForm > div {
	display: flex;
	flex-direction: column;
	text-align: left;
}

.footerForm textarea {
	border-radius: 8px;
	background: var(--Lippincott-Gray-7);
	border-color: transparent;
	margin-top: 16px;
	padding: 16px;

	color: white;
	font-family: "Noto Sans";
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
}

.footerForm textarea:focus-visible {
	outline: 0;
	border-color: white;
}

/* Specific styles */
.joinUsForm {
	max-width: 588px;
	border-bottom: 1px solid white;
	margin-top: 54px;
}

.joinUsForm button {
	background-color: transparent;
	border: 0;
	cursor: pointer;
	padding: 0;
	margin-left: 12px;
}

.contactForm {
	width: 532px;
}

.contactForm > div + div {
	margin-top: 48px;
}
.contactForm > button {
	margin-top: 16px;
	margin-left: auto;
}

.contactForm input {
	border-bottom: 1px solid white;
	padding-bottom: 0;
}

/* Buttons ===================================== */
.gradientButton {
	width: 250px;
	display: flex;
	padding: 24px;
	justify-content: center;
	align-items: center;
	border-radius: 100px;
	background: linear-gradient(107deg, #575fa9 0%, #ac25e3 98.97%);
	border: 0;
	cursor: pointer;

	color: #fff;
	font-family: "Noto Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: capitalize;
}

.gradientButton:hover {
	color: white;
}

/* Titles ===================================== */
.noe50px {
	color: white;
	font-family: "Victor Serif Regular";
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 125%;
	margin: 0;
	text-align: left;
}

.victor50px {
	color: white;
	font-family: "Victor Serif Regular";
	font-size: 50px;
	font-style: normal;
	font-weight: 500;
	line-height: 125%;
	margin: 0;
	text-align: left;
}

.noe60px {
	color: #fff;
	font-family: "Victor Serif Regular";
	font-size: 60px;
	font-style: normal;
	font-weight: 500;
	line-height: 106%;
	margin: 0;
}

/* Responsive ================================= */

@media screen and (max-width: 1024px) {
	.noe50px {
		font-size: 40px;
	}
	.victor50px {
		font-size: 40px;
	}
	.footerForm input {
		width: auto;
	}
}

@media all and (max-width: 768px) {
	.noe50px {
		font-size: 34px;
	}
	.victor50px {
		font-size: 34px;
	}
	.footerWrapper {
		max-width: 100%;
	}

	.exploringCard {
		padding: 0px;
	}
	.noe60px {
		font-size: 30px !important;
	}

	.footerWrapper .dFlex {
		flex-wrap: wrap;
	}

	.gradientButton {
		max-width: 80% !important;
	}
	.contactForm {
		width: 100%;
	}
}

@media all and (max-width: 480px) {
	.footerWrapper {
		padding-left: 20px;
		padding-right: 20px;
	}
	.joinUsForm {
		margin-top: 32px;
	}
}

@media all and (max-width: 400px) {
	.noe50px {
		font-size: 32px;
	}
	.victor50px {
		font-size: 32px;
	}
}
