/* Main CTA ======================= */
.mainCTA {
	background-color: var(--Lippincott-Gray-10);
	padding: 96px 0;
}

.mainCTA .dFlex {
	gap: 40px;
}

.mainCTA h2 {
	color: #fff;
	font-family: "Victor Serif Regular";
	font-size: 48px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
	margin: 0;
}

.mainCTA span {
	color: #fff;
	font-family: "Noto Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 110%;
}

@media all and (max-width: 768px) {
	.mainCTA h2 {
		font-size: 32px;
	}
}

@media all and (max-width: 480px) {
	.mainCTA h2 {
		font-size: 28px;
	}
	.mainCTA span {
		font-size: 18px;
	}
}
@media all and (max-width: 400px) {
	.mainCTA span {
		font-size: 16px;
	}
}
