.errorWrapper {
	padding-top: 50px;
	display: flex;
	flex-direction: column;
}

.errorWrapper div {
	text-align: left;
}
.errorWrapper h1 {
	color: white;
	font-family: "piepie", sans-serif;
	font-size: 200px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: -1px;
	text-align: left;
	margin: 0;
	line-height: 170px;
	padding: 0 30px;
}
.errorWrapper h1:nth-of-type(2) {
	margin-left: 20%;
}
.errorWrapper h2 {
	color: white;
	font-family: "piepie", sans-serif;
	font-size: 40px;
	font-style: normal;
	font-weight: 500;
	letter-spacing: -1px;
	text-align: left;
	margin: 0;
	margin-left: 500px;
}
.errorWrapper .error_1 {
	position: relative;
	float: right;
	top: 30px;
	right: 80px;
}
.errorWrapper .error_2 {
	position: relative;
	margin-left: 100px;
	top: -200px;
	margin-bottom: -200px;
	width: 370px;
}

@media screen and (max-width: 960px) {
	.errorWrapper h1 {
		font-size: 100px;
		line-height: 90px;
	}

	.errorWrapper .error_1 {
		width: 400px;
	}
	.errorWrapper .error_2 {
		width: 250px;
		top: -100px;
		margin-bottom: -100px;
	}

	.errorWrapper h2 {
		font-size: 20px;
		margin-left: 380px;
	}
}

@media screen and (max-width: 768px) {
	.errorWrapper h1 {
		font-size: 80px;
		line-height: 80px;
	}

	.errorWrapper h1:nth-of-type(1) {
		margin-top: 20px;
	}

	.errorWrapper .error_2 {
		width: 250px;
		top: -75px;
		margin-bottom: -100px;
	}
}

@media screen and (max-width: 480px) {
	.errorWrapper h1 {
		font-size: 50px;
		line-height: 50px;
	}

	.errorWrapper h1:nth-of-type(1) {
		margin-top: 50px;
	}

	.errorWrapper .error_1 {
		width: 300px;
		right: 0;
	}

	.errorWrapper .error_2 {
		width: 200px;
		top: -70px;
		margin-bottom: -70px;
		margin-left: 0;
	}

	.errorWrapper h2 {
		font-size: 20px;
		margin-left: 220px;
	}
}
