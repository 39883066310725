body {
	background: #202020;
	scrollbar-gutter: stable;
}
.App {
	text-align: center;
	/* GUTTERS */
	background: #202020;
}

html {
	scrollbar-gutter: stable;
}

@import url("https://use.typekit.net/uef8bhk.css");

@font-face {
	font-family: "piepie", sans-serif;
	font-weight: 400;
	font-style: normal;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* DEFAULT LANDING */
.main-wrapper {
	max-width: 1440px;
	/* min-width: 770px; */
	margin: auto;
	overflow-x: clip;
}

@media all and (max-width: 768px) and (min-width: 481px) {
	.main-wrapper {
		/* width:768px !important; */
	}
}

@media all and (max-width: 480px) {
	.main-wrapper {
		max-width: 100%;
		min-width: 100%;
		margin: auto;
		overflow-x: clip;
	}
}
