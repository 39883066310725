.manifesto {
	position: "relative";
	height: "100%";
	width: "100%";
}

.manifesto-black {
	position: relative;
	width: 100%;
	min-height: 530vh;
	background: #202020;
	background-blend-mode: multiply;
}
.manifesto_quotes_container {
	position: sticky;
	top: 0;
	width: 100%;
	overflow: hidden;
}

.manifesto-black_quotes {
	font-family: "piepie", sans-serif;
	font-size: 150px;
	font-weight: 500;
	line-height: 88%;
	letter-spacing: 0em;
	text-align: left;
	color: #5f5f5f;
	display: flex;
	flex-direction: column;
	z-index: 0;
	padding-top: 120px;
	padding-bottom: 90px;
	gap: 50px;
	height: calc(100vh - 210px);
	justify-content: space-around;
}
.manifesto-black_quote-1 {
	position: relative;
	padding-left: 4.375rem;
	transition: all 0.4s ease-out;
}
.manifesto-black_quote-2 {
	position: relative;
	padding-left: 4.375rem;
	transition: all 0.4s ease-out;
}
.manifesto-black_quote-3 {
	position: relative;
	text-align: right;
	padding-right: 4.375rem;
	transition: all 0.4s ease-out;
}
.manifesto-black_quote-4 {
	position: relative;
	text-align: right;
	padding-right: 4.375rem;
	transition: all 0.4s ease-out;
}

/* PARAGRAPHS */
.manifesto-black_paragraph {
	position: relative;
	width: 100%;
	height: 100vh;
	margin: auto;
	font-family: "Victor Serif Regular", sans-serif;
	font-size: 40px;
	font-weight: 500;
	line-height: 116%;
	letter-spacing: 0em;
	text-align: center;
	color: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
}
.manifesto-black_paragraph.forty-pt {
	font-size: 40px;
}
.manifesto-black_paragraph-title {
	font-family: "Victor Serif Regular", sans-serif;
	font-size: 60px;
	font-weight: 500;
	letter-spacing: 0em;
	text-align: center;
	margin-bottom: 70px;
}
.manifesto-black_paragraph-big {
	font-family: "Victor Serif Regular", sans-serif;
	font-size: 100px;
	font-weight: 500;
	line-height: 116%;
	letter-spacing: 0em;
	text-align: center;
}

/* Block Paragraph Styles  */
.first_manifesto {
	margin: 0 auto;
	max-width: 740px;
}
.second_manifesto {
	max-width: 910px;
	margin: 0 auto;
	z-index: 1;
}

.four_manifesto > div {
	max-width: 810px;
	margin: 0 auto;
	z-index: 2;
}

/* Images ============================================ */
.avocado_image_left,
.avocado_image_right {
	position: absolute;
	width: auto;
	z-index: 0;
	-webkit-user-drag: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	height: auto;
}
.avocado_image_left {
	max-width: 350px;
	left: 0;
}
.avocado_image_right {
	max-width: 200px;
	right: 90px;
}

/* Responsive ============================================ */
.viewInDesktop {
	display: none;
}
.viewInMobile {
	display: block;
}
.viewInMobile .manifesto-black_quote-1,
.viewInMobile .manifesto-black_quote-2,
.viewInMobile .manifesto-black_quote-3,
.viewInMobile .manifesto-black_quote-4 {
	padding-left: 0;
	padding-right: 0;
}
@media screen and (min-width: 481px) {
	.viewInDesktop {
		display: block;
	}
	.viewInMobile {
		display: none;
	}
}

@media screen and (max-width: 1280px) {
	.manifesto-black_quotes {
		font-size: 140px;
	}
	.avocado_image_left {
		left: -80px;
	}
	.avocado_image_right {
		right: 0;
	}
}
@media screen and (max-width: 1024px) {
	.manifesto-black {
		min-height: 440vh;
	}
	.manifesto-black_quotes {
		font-size: 110px;
	}
	.manifesto-black_paragraph {
		height: 80vh;
	}
	.avocado_image_right {
		right: -240px;
	}
}
@media screen and (max-width: 768px) {
	.manifesto-black_quotes {
		font-size: 90px;
	}

	.manifesto-black_paragraph {
		font-size: 18px;
	}
	.manifesto-black_paragraph.first_manifesto {
		max-width: 365px;
	}

	.second_manifesto {
		max-width: 500px;
	}

	.manifesto-black_paragraph-big {
		max-width: 400px;
		margin: 0 auto;
		font-size: 60px;
	}

	.manifesto-black_paragraph-title {
		font-size: 36px;
		margin-bottom: 50px;
	}

	.manifesto-black_paragraph.forty-pt {
		font-size: 24px;
		max-width: 500px;
	}
	.manifesto-black_paragraph.forty-pt p {
		margin: 50px auto;
	}
	.avocado_image_right {
		right: -170px;
		max-width: 140px;
	}
}

@media screen and (max-width: 480px) {
	.manifesto-black_quotes {
		width: 100%;
		font-size: 60px;
	}
	.manifesto-black_quotes > div:nth-of-type(1) {
		width: 500px;
	}
	.manifesto-black_quotes > div:nth-of-type(2) {
		width: 560px;
	}
	.manifesto-black_paragraph {
		font-size: 20px;
	}

	.manifesto-black_paragraph-title {
		line-height: 40px;
	}

	.first_manifesto,
	.second_manifesto,
	.four_manifesto {
		max-width: 320px;
	}
	.avocado_image_left {
		max-width: 160px;
	}
	.manifesto-black_paragraph-big {
		max-width: 320px;
		margin: 0 auto;
		font-size: 60px;
	}
	.manifesto-black_paragraph.forty-pt {
		font-size: 20px;
		max-width: 310px;
	}
	.avocado_image_right {
		right: -140px;
		max-width: 70px;
	}
	.hidden_on_phone {
		display: none;
	}
}
@media screen and (max-width: 400px) {
	.manifesto-black_quotes {
		font-size: 56px;
		justify-content: space-between;
	}
}
