:root {
	--naming-color-black: #191919;
	--naming-color-other-black: #202020;
	--naming-color-white: white;
	--naming-font-body: "Noto Sans", sans-serif;
	--naming-font-heading: "piepie", "Helvetica Neue Light", "Helvetica Neue",
		"Helvetica", "Arial", sans-serif;
	--naming-font-alt-heading: "Victor Serif Regular", sans-serif;
	--naming-font-roobert: "Roobert PRO";
}

@import url("https://use.typekit.net/uef8bhk.css");

@font-face {
	font-family: "piepie", sans-serif;
	font-weight: 400;
	font-style: normal;
}

u {
	text-underline-offset: 2px;
	text-decoration-thickness: 1px;
}

@font-face {
	font-family: "Victor Serif";
	src: url(../fonts/VictorSerif-40Regular.woff) format("woff"),
		url(../fonts/VictorSerif-40Regular.woff2) format("woff2"),
		url(../fonts/VictorSerif-40Regular.otf) format("otf");
	font-weight: 400;
}

$mobile: 992px;
$mobile-height: 920px;
$mobile-height-right-alt: $mobile-height + 50px;
$baseline-larger-breakpoint: 1450px;
$baseline-breakpoint: 1200px;
$capped: 1440px;
$maxwidth: 1440px;
$header-size: 64px;

body {
	background: var(--naming-color-black);
	margin: 0;
	.wrapper {
		max-width: $maxwidth;
		margin: 0 auto;
	}
}

.light-mode {
	h1,
	h1 .eyebrow,
	.left h1,
	.info .breadcrumb,
	.left .info .tagging a,
	.info .tagging ul li a,
	.info .tagging ul li,
	.info .tagging a,
	.title {
		color: var(--naming-color-white) !important;
	}
}

.curtain {
	// Put a background to separate the white text from the background
	position: relative;
	z-index: 0;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		left: 0;
		background: rgb(0 0 0 / 0.4);
	}
}

.exploringCard.curtain {
	&::after {
		border-radius: 20px;
	}
}

.hero {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0;
	height: calc(100dvh - $header-size);
	padding-block-start: $header-size;
	// height: 100dvh;

	background-color: var(--naming-color-black);

	position: relative;
	z-index: 0;

	.eyebrow {
		color: #020202;
		font-family: var(--naming-font-roobert);
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%; /* 36px */
		letter-spacing: -0.4px;
		text-transform: uppercase;
		/* 
		@media (max-height: 1100px) and (min-width: $mobile) {
			font-size: 35px;
		}
		@media (max-height: 1100px) and (min-width: $mobile) {
			font-size: 25px;
		} */
	}

	&:before {
		position: absolute;
		content: "";
		background-color: black;
		background-size: cover;
		width: 100%;
		height: 100%;
		opacity: 0.25; //was .45 but the text is illegible
		mix-blend-mode: lighten;
		@media ((max-height: $mobile-height) and (min-width: $mobile)) {
			opacity: 0.1;
		}
		@media (max-width: $mobile) {
			left: 0;
		}
	}

	@media (max-width: $mobile) {
		display: block;
		height: auto;
	}

	.left {
		background: transparent url(../images/naming/header-new.jpg) center center
			no-repeat;
		background-size: cover;
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		z-index: 1;

		&.ai-culture {
			background-image: url(../images/ai-culture/ai-culture-header.jpg);
		}

		&.listening-live {
			background-image: url(../images/listening-live/article2_header_comp.jpg);
		}

		&.listening {
			background-image: url(../images/listening/article3_header_comp.jpg);
		}

		&.replicating {
			background-image: url(../images/replicating/gundy1479_A_futuristic_library_with_many_books_and_crowded_peop_0767a76b-ceab-4013-a86e-d1b39b48721b\ 1.png);
		}

		&.lilah {
			background-image: url(../images/lilah/gundy1479_A_futuristic_library_with_many_people_walking_around__dffc0ba9-530f-420d-9b8b-22466e9f083e\ 1.jpg);
		}

		.info {
			color: var(--naming-color-white);

			padding-inline-start: 10.2%;

			bottom: 0;
			position: absolute;
			padding-block-end: 15px;

			.breadcrumb {
				font-family: var(--naming-font-body);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 19.2px */
				letter-spacing: 1px;
				text-transform: uppercase;
				color: var(--naming-color-black);
				text-align: left;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			.tagging {
				padding: 0;
				font-family: var(--naming-font-body);
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				color: var(--naming-color-black);

				ul {
					margin: 0;
					margin-block-start: 6px;
					padding: 0;
					list-style-type: none;
					li {
						display: inline-block;
						margin-inline-end: 5px;
						color: var(--naming-color-black);
						a {
							color: inherit;
							text-decoration: none;
						}
					}
				}
			}
		}
	}

	.right {
		position: relative;
		color: var(--naming-color-white);

		display: flex;
		flex-direction: column;
		font-family: var(--naming-font-body);
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		justify-content: center;
		text-align: left;

		@media (max-height: 1100px) and (min-width: $mobile) {
			font-size: 16px;
		}

		section {
			padding-inline: 12%;
			position: absolute;
			@media (max-width: $mobile) or ((max-height: $mobile-height) and (min-width: $mobile)) {
				position: relative;
				margin-block: 2em 4em;
			}

			p {
				font-family: inherit;
				font-size: 18px;
			}

			/* NO CAPS */

			/*
			& p:not(.no-cap):not(.initial-cap):not(.standout-cap):first-of-type {
				&::first-letter {
					font-family: var(--naming-font-alt-heading);
					font-style: normal;
					line-height: 1;
					color: var(--naming-color-white);
					padding: 0 0 0 0;
					margin-right: 6px;
					float: left;
					font-size: 300%;
					margin: 0 0 -0.3em 0;
				}
			}

			& p.initial-cap:not(.no-cap) {
				&::first-letter {
					font-family: var(--naming-font-alt-headingg);
					font-style: normal;
					line-height: 1;
					color: var(--naming-color-white);
					padding: 6px 0 0 0;
					margin-right: 2px;
					float: none;

					font-size: 300%;
				}
			}
			& p.standout-cap:not(.no-cap) {
				&::first-letter {
					font-family: var(--naming-font-alt-heading);
					font-style: normal;
					line-height: 1;
					color: var(--naming-color-white);
					padding: 6px 3px 0 0;
					margin-right: 6px;
					float: none;

					font-size: 300%;
					// margin: 0 0 -0.3em 0;
				}
			}

			*/
		}
	}

	h1 {
		color: #020202;
		font-family: var(--naming-font-alt-heading);
		font-size: 100px;
		font-style: normal;
		font-weight: 500;
		line-height: 90%;
		letter-spacing: -1px;
		width: 68.3%;
		padding-inline-start: 10.2%;
		text-align: left;
		@media (max-width: $baseline-larger-breakpoint) {
			font-size: 80px;
		}

		@media (max-width: $mobile) {
			font-size: 60px;
			margin-block: 2em;
		}

		@media (max-height: 1100px) and (min-width: $mobile) {
			font-size: 75px;
		}
		@media (max-height: 1100px) and (min-width: $mobile) {
			font-size: 55px;
		}
	}
	&--full {
		display: grid;
		grid-template-columns: 1fr;
		gap: 0;
		height: calc(100dvh - $header-size);
		padding-block-start: $header-size;
		// height: 100dvh;

		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		background-color: var(--naming-color-black);
		position: relative;
		z-index: 0;

		h1 {
			color: #020202;
			font-family: var(--naming-font-alt-heading);
			font-size: 100px;
			font-style: normal;
			font-weight: 500;
			line-height: 90%; /* 90px */
			letter-spacing: -1px;
			width: 68.3%;
			padding-inline-start: 10.2%;
			text-align: left;
			@media (max-width: $baseline-larger-breakpoint) {
				font-size: 80px;
			}

			@media (max-width: $mobile) {
				font-size: 60px;
				margin-block: 2em;
			}
		}

		// &.light-mode {
		// 	h1,
		// 	.info .breadcrumb,
		// 	.info .tagging ul li a {
		// 		color: var(--naming-color-white);
		// 	}
		// }

		.info {
			color: var(--naming-color-white);

			padding-inline-start: 10.2%;

			bottom: 0;
			position: absolute;
			padding-block-end: 15px;

			.breadcrumb {
				font-family: var(--naming-font-body);
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 120%; /* 19.2px */
				letter-spacing: 1px;
				text-transform: uppercase;
				color: var(--naming-color-black);
				text-align: left;

				a {
					color: inherit;
					text-decoration: none;
				}
			}

			.tagging {
				padding: 0;
				font-family: var(--naming-font-body);
				font-size: 20px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
				color: var(--naming-color-black);

				ul {
					margin: 0;
					margin-block-start: 6px;
					padding: 0;
					list-style-type: none;
					li {
						display: inline-block;
						color: inherit;
						a {
							color: inherit;
							text-decoration: none;
							margin-inline-end: 4px;
						}
					}
				}
			}
		}
		.title {
			position: absolute;
			top: 0;
			right: 30px;

			color: var(--naming-color-white);
			font-family: var(--naming-font-heading);
			font-size: 64px;
			font-style: normal;
			font-weight: 500;
			line-height: 90%;
			text-transform: uppercase;
			margin-block: 80px 0;

			@media (max-width: 1100px) or (max-height: $mobile-height) {
				font-size: 65px;
				margin-block: 30px 0;
			}

			@media (max-width: 992px) and (max-height: $mobile-height) {
				font-size: 45px;
				margin-block: 30px 0;
				top: 45px;
			}

			@media (max-width: 992px) {
				position: static;
				margin: 30px 30px 0 0;
				display: flex;
				flex-wrap: wrap;
				align-self: flex-end;
				flex-direction: column;
			}

			@media (min-width: 992px) and (max-height: 650px) {
				display: none;
			}

			@media (max-height: 780px) and (min-width: $mobile) {
				display: none;
			}

			span {
				display: block;
				line-height: 1;
				text-align: center;
				text-transform: none;
				font-size: 14px;

				color: var(--naming-color-white);
				font-family: "Victor Serif";
				font-style: normal;
				font-weight: 500;
			}
		}

		&.humans {
			background: transparent url(../images/humans/article1_header_comp.jpg)
				center center no-repeat;
			background-size: cover;
		}
	}
}

.scrollingsections {
	position: relative;
	max-width: $maxwidth;
	overflow: hidden;
}

.baseline {
	background: url(../images/naming/baseline.jpg) top left no-repeat;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 96px;
	color: var(--naming-color-white);
	position: relative;
	background-size: cover;
	height: 100vh;
	grid-auto-rows: 1fr;

	@media (max-width: $mobile) {
		height: auto;
	}

	&.baseline2 {
		background: url(../images/naming/baseline2.jpg) no-repeat;
		background-size: cover;
		background-position: center;
		@media (max-width: $mobile) {
			height: auto;
		}
	}

	&.baseline3 {
		background: url(../images/naming/baseline3.jpg) top left no-repeat;
		background-size: cover;
		@media (max-width: $mobile) {
			height: auto;
		}
	}

	@media (max-width: $mobile) {
		display: block;
		height: auto;
		padding: 2em;
	}

	.left {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		padding-inline: 60px;
		-webkit-align-items: center;

		color: var(--naming-color-white);
		@media (max-width: $baseline-breakpoint) {
			padding-inline: 30px;
			margin-block-end: 30px;
		}

		.section-title {
			color: var(--naming-color-white);
			margin: 0;
			padding: 0;
			font-family: var(--naming-font-alt-heading);
			font-size: 100px;
			font-style: normal;
			font-weight: 500;
			line-height: 100%; /* 100px */
			text-transform: uppercase;
			text-align: center;
			span {
				display: block;
				margin-block: -10px;
				color: rgba(255, 255, 255, 0.6);

				text-align: center;
				font-family: var(--naming-font-alt-heading);
				font-size: 500px;
				font-style: normal;
				font-weight: 500;
				line-height: 100%; /* 500px */
			}
			@media (max-width: $baseline-larger-breakpoint) {
				font-size: 90px;
				span {
					font-size: 400px;
				}
			}

			@media (max-width: $baseline-breakpoint) or ((max-height: $mobile-height) and (min-width: $mobile)) {
				font-size: 65px;
				span {
					font-size: 300px;
				}
			}
			@media (max-width: $mobile) {
				font-size: 55px;
				padding-inline-end: 32px;

				span {
					font-size: 250px;
				}
			}
		}
	}

	.right {
		color: var(--naming-color-white);
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
		padding-inline-end: 85px;
		text-align: left;

		.section-title {
			font-family: var(--naming-font-alt-heading);
			font-size: 60px;
			font-style: normal;
			font-weight: 500;
			line-height: 100%; /* 60px */
			margin: 0;
			padding: 0;
		}

		p {
			color: var(--naming-color-white);
			font-family: var(--naming-font-body);
			font-size: 20px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
		}

		@media (max-width: $mobile) or ((max-height: $mobile-height) and (min-width: $mobile)) {
			padding-inline-end: 20px;
			.section-title {
				font-size: 35px;
			}
		}

		@media (min-width: $mobile) and (max-width: $capped) {
			padding-inline-end: 110px;
		}
	}
}

.scroll-element {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0;

	background: #33353e;
	iframe,
	video {
		width: 100%;
		padding: 0;
		margin: 0;
		object-fit: cover;
		z-index: 1000;
		left: auto !important;
		@media (max-width: $mobile) {
			max-height: fit-content;
		}

		&[poster] {
			//some posters are larger than their videos. if we use a [data-height] attribute, we can try to accomodate as much as possible
			object-fit: fill;
			object-fit: contain;
			width: 100%;
			@media (max-width: 375px) {
				width: calc(100% - 30px);
			}
			&[data-height="405"] {
				height: 405px;
			}
			&[data-height="335"] {
				height: 335px;
			}
		}
	}

	section.video {
		position: relative;
		width: 100%;

		position: absolute;
		top: 50%;
		transform: translateY(-50%);

		img {
			width: 100%;

			&.narrower {
				width: 70%;
			}
		}

		@media (max-width: $mobile) {
			top: auto;
			transform: none;
		}
	}

	&.top-aligned {
		section.video {
			top: 75px;
			transform: none;
		}
	}

	@media (max-width: $mobile) {
		display: block;
	}

	.image-section {
		margin: 0;
		padding: 0;
		width: 100%;
		display: none;
		img {
			width: 100%;
			object-fit: contain;
		}
	}

	.left {
		padding-block-start: 0;
		background: transparent;
		transform: none !important; //testing locally because this element disappears locally
		.mediawrapper {
			height: 100dvh;
			position: relative;
		}

		@media (max-width: $mobile) {
			display: none;
		}
	}

	.right {
		padding: 96px 71px;
		text-align: left;
		@media (max-width: $mobile) {
			padding-inline: 32px;
			padding-block: 32px;
		}
		@media (max-width: 375px) {
			padding-inline: 32px 45px;
		}
		background: linear-gradient(
			116deg,
			#d2f1ff 1.25%,
			#eaf9ff 40.16%,
			#fff4de 77.71%,
			#ffe0ca 100%
		);
		img {
			width: 100%;
			display: block;
		}

		iframe,
		video,
		.image-section {
			display: none;
			@media (max-width: $mobile) {
				display: block;
			}
		}

		.video {
			position: relative;
			margin: 100px 0;
			z-index: 1;
			@media (min-width: $mobile) {
				display: none;
			}

			@media (max-width: $mobile) {
				margin: 50px 0;
			}
		}

		&.third {
			padding-block: 130px;

			fill: linear-gradient(
				116deg,
				#d2f1ff 1.25%,
				#eaf9ff 40.16%,
				#fff4de 77.71%,
				#ffe0ca 100%
			);
			background-blend-mode: multiply;
		}

		color: var(--naming-color-other-black);
		font-family: var(--naming-font-body);
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;

		.section-headline {
			color: var(--naming-color-other-black);
			text-wrap: balance;
			// width: 95%;
			@media (max-width: $mobile) {
				width: auto;
			}
			font-family: var(--naming-font-alt-heading);
			font-size: 60px;
			font-style: normal;
			font-weight: 500;
			line-height: 95%;
			margin: 0 0 42px 0;
			padding: 0;
		}

		.section-heading-larger {
			// width: 70%;
			@media (max-width: $mobile) {
				width: auto;
			}
			color: var(--naming-color-other-black);
			font-family: var(--naming-font-alt-heading);
			font-size: 60px;
			font-style: normal;
			font-weight: 500;
			line-height: 95%;
			text-wrap: balance;
		}

		ul,
		ol {
			list-style-type: none;
			padding-inline-start: 0;
			margin-block-start: 0;
			li {
				margin-block-end: 1.5em;
				@media (max-width: $mobile) {
					margin-block-end: 0.75em;
				}
			}
		}

		ul,
		ol {
			&.list-style {
				list-style-type: none;
				padding-inline-start: 30px;
				margin-block-start: initial;
				li {
					list-style: initial;
				}
			}
		}

		.section-subhead {
			color: var(--naming-color-other-black);
			font-family: var(--naming-font-alt-heading);
			font-size: 30px;
			font-style: normal;
			font-weight: 500;
			line-height: 110%; /* 33px */
			margin: 0;
			padding: 0;
			margin-block-end: 1em;

			&-smaller {
				color: var(--naming-color-other-black);
				text-align: center;
				font-family: var(--naming-font-alt-heading);
				font-size: 30px;
				font-style: normal;
				font-weight: 500;
				line-height: normal;
			}

			.centered {
				text-align: center;
			}
			@media (max-width: $mobile) {
				max-width: none;
			}
			&-larger {
				@extend .section-heading-larger;
			}
			&.special {
				margin-block-start: 70px;

				&.lesson {
					& + ul {
						li {
							margin-bottom: 1.5em;
						}
					}
				}
			}
		}

		.block {
			color: var(--naming-color-other-black);
			font-family: var(--naming-font-alt-heading);
			font-size: 40px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			@media (max-width: $mobile) {
				font-size: 30px;
			}
		}

		.origination {
			margin-block: 70px;
		}

		.tagging {
			list-style-type: none;
			padding-inline-start: 0;
			font-style: italic;
			li {
				margin-block-end: 0;
			}
		}
	}
}

.hide {
	display: none;
}

p.initial-cap {
	&::first-letter {
		font-family: var(--naming-font-alt-heading);
		font-style: normal;
		line-height: 1;
		// color: var(--naming-color-white);
		color: currentColor;
		padding: 6px 0 0 0;
		margin: 0;
		float: none;

		font-size: 300%;
	}
}

p.drop-cap {
	&::first-letter {
		font-family: var(--naming-font-alt-heading);
		font-style: normal;
		line-height: 1;
		color: currentColor;
		padding: 0 3px 0 0;
		margin-right: 6px;
		float: left;

		font-size: 300%;
		margin: 0 0 -0.3em 0;
	}
}

p.standout-cap {
	&::first-letter {
		font-family: var(--naming-font-alt-heading);
		font-style: normal;
		line-height: 0.5;
		color: currentColor;
		padding: 6px 3px 0 0;
		margin-right: 0px;
		margin-left: -0.6em;
		float: none;
		font-size: 3em;
	}
}

code {
	border-radius: 19px;
	border: 1.5px solid #000;
	background: #fff;
	display: block;
	padding: 21px 15px;
	color: var(--naming-color-other-black);
	font-family: var(--naming-font-body);
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
