/* Defining colors and variables ================== */
:root {
	--light-gray: #252525;
	--Lippincott-Gray-4: #adadad;
	--Lippincott-Gray-5: #8f8f8f;
	--Lippincott-Gray-7: #252525;
	--Lippincott-Gray-8: #202020;
	--Lippincott-Gray-10: #111;
	--Lippincott-Black: #191919;
	--Lippincott-Lavender-Medium: #ba9cff;
}

/* Defining structure ============================= */
.dFlex {
	display: flex;
}
.spaceBetween {
	justify-content: space-between;
}
.alignItemsCenter {
	align-items: center;
}
.alignItemsStart {
	align-items: flex-start;
}
.flexColumn {
	flex-direction: column;
}

.robotsSection {
	background: linear-gradient(
		116deg,
		#e2ffd4 6.06%,
		#e9fffa 38.98%,
		#fff2d0 71.89%,
		#ffe6de 104.81%
	);
	background-blend-mode: multiply;
	position: sticky;
	overflow: hidden;
}

.robotsSection p,
.robotsSection ul li {
	color: #000;
	font-family: "Noto Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}

.robotsSection ul li {
	list-style-type: disc;
}

.robotsWrapper {
	max-width: 1290px;
	margin: 0 auto;
	padding-left: 24px;
	padding-right: 24px;
	text-align: left;
}

.robotsWrapper > div {
	position: relative;
}

.robotsWrapper img {
	position: absolute;
	z-index: -1;
}

.robotHeading {
	color: #000;
	font-family: "Victor Serif Regular", sans-serif;
	font-size: 150px;
	font-style: normal;
	font-weight: 500;
	line-height: 84%;
	margin: 0;
}
.grayHeadings .robotHeading {
	color: #5f5f5f;
}

.robotBlockOne {
	padding: 130px 0 52px;
}
.robotBlockOne h2:nth-of-type(1) {
	padding-left: 166px;
}
.robotBlockOne h2:nth-of-type(3) {
	padding-left: 257px;
}
.robotOne {
	top: -120px;
	right: 3%;
	width: 335px;
	position: absolute;
	transition: all 0.4s ease-out;
}

.robotBlockTwo {
	padding: 52px 0 38px;
	position: relative;
}
.robotBlockTwo h2:nth-of-type(2) {
	padding-left: 62px;
}
.robotBlockTwo h2:nth-of-type(3) {
	padding-left: 216px;
}
.robotBlockTwo .robotTextWrapper:nth-of-type(1) {
	max-width: 636px;
	margin-bottom: 80px;
}
.robotBlockTwo .robotTextWrapper:nth-of-type(2) {
	max-width: 590px;
	margin-top: 40px;
}

.robotTwo {
	position: absolute;
	top: 240px;
	right: -22%;
	width: 782px;
	z-index: 0;
	/*transition: all 0.4s ease-out;*/
}

.robotBlockThree {
	padding: 38px 0 0;
	position: relative;
}
.robotBlockThree .robotsWrapper {
	text-align: right;
}

.robotBlockThree .headingBlockOne h2:nth-of-type(1) {
	padding-right: 52px;
}
.robotBlockThree .headingBlockOne h2:nth-of-type(3) {
	padding-right: 264px;
}
.robotBlockThree .headingBlockTwo h2:nth-of-type(1) {
	padding-right: 158px;
}
.robotBlockThree .headingBlockTwo h2:nth-of-type(2) {
	padding-right: 196px;
}

.robotBlockThree .robotTextWrapper {
	max-width: 480px;
	margin-left: auto;
	text-align: left;
	margin-bottom: 150px;
}

.robotThree {
	position: absolute;
	top: 90px;
	left: -12%;
	width: 848px;
	z-index: 0;
	/*transition: all 0.4s ease-out;*/
}

.robotBlockFour {
	padding: 0 0 440px;
	text-align: right;
	position: relative;
}

.robotBlockFour .robotTextWrapper {
	max-width: 638px;
	margin-left: auto;
	text-align: left;
}

.robotBeach {
	position: absolute;
	left: -32%;
	bottom: 0;
	width: 2180px;
	z-index: 0;
	/*transition: all 0.4s ease-out;*/
}

/* Responsive ================================= */
@media (max-width: 1280px) {
	.robotsWrapper {
		padding-left: 32px;
		padding-right: 32px;
	}
	.robotHeading {
		font-size: 130px;
	}
	.robotOne {
		width: 330px;
	}
	.robotTwo {
		width: 680px;
		top: 350px;
	}
	.robotThree {
		width: 760px;
	}
	.robotBeach {
		width: 1880px;
	}
}

@media (max-width: 1280px) {
	.robotHeading {
		font-size: 100px;
	}
	.robotOne {
		width: 250px;
	}
	.robotTwo {
		width: 600px;
	}
	.robotThree {
		width: 800px;
	}
	.robotBeach {
		width: 1580px;
	}
}

@media (max-width: 1180px) {
	.robotThree {
		width: 650px;
		top: 20px;
	}
}

@media (max-width: 1024px) {
	.negativeRow {
		margin-left: -32px;
		margin-right: -32px;
	}

	.robotHeading {
		font-size: 86px;
	}

	.robotsSection p,
	.robotsSection ul li {
		font-size: 12px;
	}

	.robotBlockOne {
		padding-top: 80px;
		padding-bottom: 0;
	}

	.robotBlockOne h2:nth-of-type(1) {
		padding-left: 80px;
	}

	.robotBlockOne h2:nth-of-type(2) {
		padding-left: 40px;
	}

	.robotBlockOne h2:nth-of-type(3) {
		padding-left: 200px;
	}

	.robotOne {
		width: 200px;
		top: -60px;
	}

	.robotBlockTwo h2:nth-of-type(1) {
		padding-left: 0px;
	}

	.robotBlockTwo h2:nth-of-type(2) {
		padding-left: 40px;
	}

	.robotBlockTwo h2:nth-of-type(3) {
		padding-left: 160px;
	}

	.robotTwo {
		width: 550px;
		top: 60px;
		bottom: 0;
	}

	.robotBlockTwo {
		padding-bottom: 10px;
	}

	.robotBlockTwo .robotTextWrapper:nth-of-type(1) {
		margin-bottom: 40px;
	}

	.robotBlockTwo .robotTextWrapper:nth-of-type(2) {
		margin-top: 20px;
	}

	.robotBlockTwo .robotsWrapper .robotTextWrapper:nth-of-type(1) p {
		max-width: 450px;
	}

	.robotBlockTwo .robotsWrapper .robotTextWrapper:nth-of-type(2) p {
		max-width: 450px;
	}

	.robotBlockThree {
		padding-top: 0px;
	}

	.robotBlockThree .headingBlockOne h2:nth-of-type(1) {
		padding-right: 80px;
		padding-left: 0;
	}

	.robotBlockThree .headingBlockOne h2:nth-of-type(2) {
		padding-right: 0;
	}

	.robotBlockThree .headingBlockOne h2:nth-of-type(3) {
		padding-right: 147px;
		padding-left: 0;
	}

	.robotBlockThree .headingBlockTwo h2:nth-of-type(1) {
		padding-right: 120px;
		padding-left: 0;
	}

	.robotBlockThree .headingBlockTwo h2:nth-of-type(2) {
		padding-right: 160px;
	}

	.robotBlockThree .headingBlockTwo h2:nth-of-type(3) {
		padding-right: 70px;
		padding-left: 0;
	}

	.robotBlockThree .robotTextWrapper {
		padding-bottom: 0px;
		margin-bottom: 70px;
		max-width: 260px;
	}

	.robotThree {
		width: 650px;
		top: 20px;
	}

	.robotBlockFour .robotsWrapper .robotTextWrapper {
		max-width: 500px;
		float: right;
	}

	.robotBlockFour {
		padding: 0 0 370px;
	}

	.robotBlockFour .robotTextWrapper {
		margin-left: 0;
	}

	.robotBeach {
		left: -46%;
		width: 1260px;
	}
}

@media (max-width: 950px) {
	.robotTwo {
		width: 450px;
		top: 60px;
		bottom: 0;
	}
	.robotThree {
		width: 550px;
		top: 20px;
	}
}
@media (max-width: 830px) {
	.robotTwo {
		width: 390px;
		top: 60px;
		bottom: 0;
	}
	.robotThree {
		width: 500px;
		top: 20px;
	}
}

@media (max-width: 768px) {
	.robotBlockTwo .robotsWrapper .robotTextWrapper:nth-of-type(1) p {
		max-width: 400px;
	}

	.robotBlockTwo .robotsWrapper .robotTextWrapper:nth-of-type(2) p {
		max-width: 320px;
	}

	.robotBlockFour .robotsWrapper .robotTextWrapper {
		max-width: 350px;
		float: right;
	}

	.robotTwo {
		width: 380px;
		top: 130px;
		bottom: 0;
		transform: rotate(30deg);
	}

	.robotThree {
		width: 530px;
		top: 50px;
		transform: rotate(-30deg);
	}
}

@media (max-width: 680px) {
	.robotTwo {
		width: 340px;
		top: 160px;
		bottom: 0;
	}

	.robotThree {
		width: 500px;
	}
}

@media all and (max-width: 640px) {
	.robotHeading {
		font-size: 60px;
	}
	.robotsSection p,
	.robotsSection ul li {
		font-size: 16px;
	}
	.robotsSection ul {
		padding-inline-start: 32px;
	}

	.robotOne {
		width: 170px;
	}
	.robotBlockOne {
		padding-top: 360px;
	}
	.robotBlockOne h2:nth-of-type(1) {
		padding-left: 37px;
	}
	.robotBlockOne h2:nth-of-type(3) {
		padding-left: 90px;
	}

	.robotTwo {
		width: 410px;
		bottom: 0;
		top: 770px;
		transform: rotate(0deg);
	}

	.robotThree {
		transform: rotate(0deg);
	}

	.robotBlockTwo {
		padding-bottom: 440px;
	}
	.robotBlockTwo h2:nth-of-type(1) {
		padding-left: 54px;
	}
	.robotBlockTwo h2:nth-of-type(3) {
		padding-left: 155px;
	}

	.robotBlockThree .headingBlockOne h2:nth-of-type(1),
	.robotBlockThree .headingBlockTwo h2:nth-of-type(1) {
		padding-left: 47px;
	}
	.robotBlockThree .headingBlockOne h2:nth-of-type(3),
	.robotBlockThree .headingBlockTwo h2:nth-of-type(3) {
		padding-left: 107px;
	}

	.robotThree {
		top: 600px;
	}

	.robotBlockFour {
		padding-top: 600px;
		padding-bottom: 750px;
	}

	.robotBeach {
		left: -76%;
		width: 1180px;
	}
}

@media all and (max-width: 480px) {
	.robotTwo {
		top: 850px;
	}
}
