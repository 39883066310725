/* KeepExploring ======================= */
.keepExploring {
	background-color: var(--Lippincott-Gray-8);
	padding: 100px 0 90px;
}

.cardContainer {
	display: flex;
	overflow-x: auto;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
	padding-bottom: 20px; /* Add some bottom padding for better scrolling experience */
}

.cardContainer::-webkit-scrollbar {
	display: none; /* Hide the scrollbar for WebKit browsers */
}

.cardContainer .dFlex {
	overflow-x: scroll;
}

/* Hide the scrollbar for WebKit browsers */
/* .cardContainer .dFlex::-webkit-scrollbar {
	display: none;
} */

.exploringCard {
	margin-top: 48px;
	padding: 337px 55px 67px;
	border-radius: 20px;
	text-align: left;
	background-size: cover;
	background-position: center;
	position: relative;
	flex: 0 0 auto;
	width: 300px;
	margin-right: 20px;
	height: 400px; /* Add a fixed height for desktop */
}

.exploringCard::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	border-radius: 20px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: linear-gradient(
		82deg,
		rgba(0, 0, 0, 0.2) 12.48%,
		rgba(0, 0, 0, 0) 94%
	);
	z-index: 1;
}

.exploringCard h4 {
	position: relative;
	max-width: 534px;
	z-index: 10;
}

@media screen and (max-width: 1024px) {
	.exploringCard {
		padding: 330px 32px 32px;
	}
}

@media screen and (max-width: 480px) {
	.exploringCard {
		padding-top: 200px;
	}
}

@media screen and (max-width: 400px) {
	.exploringCard {
		padding: 200px 24px 24px;
	}
}

@media screen and (max-width: 768px) {
	.exploringCard {
		padding: 20px; /* Adjust padding for mobile */
		height: 200px; /* Set a fixed height of 200px for mobile */
	}
}
