/* Copyright ================================== */
.copyright {
    background-color: var(--light-gray);
    padding: 20px 0;
  }
  
  .copyTxt,
  .legalLinks li > a  {
    color: white;
    font-family: "Roobert PRO";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
  }
  
  .legalLinks { margin: 0; }
  .legalLinks li + li { margin-left: 30px; }
  .legalLinks li > a {
    color: var(--Lippincott-Gray-5);
  }
  .legalLinks li > a:hover {
    color: white;
  }

  @media screen and (max-width: 1024px) {
    .copyrightContentWrapper {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
  }

@media screen and (max-width: 768px) {
  .legalLinks {
    flex-direction: column;
    gap: 16px;
  }
  .legalLinks li + li {
    margin-left: 0;
  }
}