/* Newsletter ================================== */
.newsletter {
    background-color: var(--Lippincott-Black);
    padding: 120px 0;
}

.newsletterInput {
    max-width: 640px;
}


/* Site links ============================== */
.siteLinks {
    max-width: 420px;
    flex-wrap: wrap;
    gap: 32px;
}

.siteLinksContainer,
.socialLinks {
    min-width: 194px;
}

.siteLinksContainer ul,
.socialLinks ul {
    margin: 0;
    padding: 0;
}

.siteLinksContainer ul > li { text-align: left; }
.siteLinksContainer ul > li + li { margin-top: 24px; }

.siteLinksContainer ul > li a {
    color: white;
    font-family: "Victor Serif Regular";
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 110%;
}

.siteLinksContainer ul > li a:hover {
    color: var(--Lippincott-Lavender-Medium);
}

.socialLinks ul {
    gap: 20px;
}

@media screen and (max-width: 1024px) {
    .newsletterInput {
        width: 50%;
        max-width: none;
    }
    .siteLinks {
        width: 40%;
        max-width: none;
    }
}
@media all and (max-width: 768px) {
    .newsletterContentWrapper {
        flex-direction: column;
        gap: 64px;
    }
    .newsletterInput, .siteLinks {
        width: 100%;
    }
}
@media all and (max-width: 480px) {
    .newsletter {
        padding: 68px 0 32px;
    }
    .newsletterContentWrapper {
        gap: 48px;
    }
    .siteLinksContainer, .socialLinks {
        min-width: 160px;
    }
    .siteLinksContainer ul > li a {
        font-size: 20px;
    }
    .socialLinks {
        margin-top: 8px;
    }
    .socialLinks svg {
        width: 26px;
        height: auto;
    }
}

@media all and (max-width: 400px) {
    .siteLinksContainer, .socialLinks {
        min-width: 140px;
    }
}