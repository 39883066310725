/* Structure ============================================= */
:root {
	--text-black: #020202;
}
.contentWrapper {
	padding-left: 32px;
	padding-right: 32px;
}
.contentWrapperDesktop {
	display: block;
}
.contentWrapperMobile {
	display: none !important;
}
.namingWrapper {
	background: linear-gradient(
		316deg,
		#d2f1ff 15.57%,
		#eaf9ff 54.49%,
		#fff4de 92.04%,
		#ffe0ca 114.33%
	);
	background-blend-mode: multiply;
	position: relative;
	z-index: 4;
}
.pageTitle {
	color: var(--text-black);
	font-family: var(--naming-font-alt-heading);
	font-size: 100px;
	font-style: normal;
	font-weight: 500;
	line-height: 90%;
	letter-spacing: -1px;
	text-align: left;
	margin: 15vh 0 0;
	max-width: 1135px;
}

/* Hero ========================================== */
.heroNaming {
	width: 100%;
	height: calc(100vh - 64px);
	background-size: 522px, 999px;
	background-repeat: no-repeat;
	background-position: top left, right bottom;
	padding-top: 64px;
	display: flex;
	flex-direction: column;
}
.heroNaming .tagline {
	padding: 40px 20px;
	margin-left: auto;
}
.breadCrumbs {
	margin-top: auto;
	margin-bottom: 10px;
	text-align: left;
}
.breadCrumb {
	display: flex;
	gap: 13px;
}
.breadCrumb span {
	color: #000;
	font-family: "Noto Sans";
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 120%;
	letter-spacing: 1px;
	text-transform: uppercase;
}
.hastag {
	color: var(--text-black);
	font-family: "Noto Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

/* Top Section ========================================== */
.topSection {
	position: relative;
	padding-bottom: 200px;
}
.topSection .contentWrapper {
	display: flex;
	flex-direction: column;
	gap: 310px;
	width: inherit;
}
.topSection .textContent {
	width: 50%;
	padding-top: 100px;
	max-width: 638px;
	margin-left: auto;
	text-align: left;
	display: flex;
	flex-direction: column;
	gap: 75px;
}
.namingParagraph {
	font-family: "Noto Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
}
.namingParagraph.capitalized p::first-letter {
	color: #000;
	font-family: var(--naming-font-alt-heading);
	/* font-size: 100px;
  font-style: normal;
  font-weight: 500;
  float: left;
  margin-top: 25px;
  line-height: 50px;
  margin-right: 3px; */
	float: left;
	font-size: 9.8rem;
	line-height: 0.6;
	margin: 0.21em 0.1em 0em 0;
	padding: 0;
}
.namingTitle40px {
	font-family: var(--naming-font-alt-heading);
	font-size: 40px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
}
.namingQuote {
	padding-left: 35px;
	position: relative;
}
.namingQuote::before {
	content: "";
	width: 7px;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--text-black);
}
.namingQuote li {
	color: var(--text-black);
	font-family: "Victor Serif";
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.floatingImage-container {
	position: sticky;
	height: auto;
}
.topSection .floatingImage {
	position: absolute;
	height: auto;
	visibility: visible;
	left: 0px;
	/* transition: all 1000ms cubic-bezier(0.670, 0.005, 0.290, 0.995); */
}
.topSection .floatingImage.verizonPhone {
	width: 603px;
	top: 110px;
	left: -95px;
	z-index: 1;
}
.topSection .floatingImage.offJhonson {
	width: 560px;
	/*top: 536px;*/
	left: -163px;
	z-index: 2;
}
.topSection .floatingImage.duracell {
	width: 376px;
	/*top: 1300px;*/
	left: -42px;
	z-index: 3;
}
.topSection .floatingImage.sprite {
	width: 595px;
	top: 1143px;
	left: -150px;
	z-index: 4;
}
.topSection .floatingImage.robotott {
	width: 535px;
	top: 1916px;
	left: 120px;
	z-index: 7;
}
.topSection .floatingImage.dog3D {
	width: 301px;
	top: 2026px;
	left: 10px;
	z-index: 6;
}
.topSection .floatingImage.guitar3D {
	width: 336px;
	top: 2112px;
	left: -40px;
	z-index: 8;
}
.topSection .floatingImage.touros {
	width: 1103px;
	top: 2406px;
	left: 11px;
	z-index: 5;
}
.topSection .floatingImage.sphere {
	width: 581px;
	top: 2514px;
	left: 721px;
	z-index: 4;
}

/* Demo styles ---------------------------------- */
.topSection.showDemo .floatingImage:not(.demoImage) {
	left: -1000px;
	visibility: hidden;
}

.topSection.showDemo {
	height: 800vh;
}

.topSection .floatingImage.demoImage {
	width: 526px;
	top: 2184px;
	left: 90px;
	z-index: 7;
	/* transition: all 1000ms cubic-bezier(0.670, 0.005, 0.290, 0.995); */
}
.topSection.showDemo .floatingImage.demoImage {
	transform: rotate(5deg) scale(1.2) translateY(-60px);
}
.regenerateBadge {
	font-family: "B612 Mono", monospace;
	padding: 0.6rem 1.1rem;
	font-size: 18px;
	background: rgba(0, 0, 0, 1);
	color: white;
	text-align: center;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 15px 120px;
	margin: 0 30px;
}
.topSection .interactionContent {
	width: 100%;
	max-width: 638px;
	margin-left: auto;
	text-align: left;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 9999;
	transition: all 1000ms cubic-bezier(0.67, 0.005, 0.29, 0.995);
}
.topSection.showDemo .interactionContent {
	margin-top: 144px;
}
.interactionContent h3 {
	color: var(--text-black);
	font-family: var(--naming-font-alt-heading);
	font-size: 200px;
	font-style: normal;
	font-weight: 500;
	line-height: 75%;
	text-transform: uppercase;
	margin: 0;
}
.interactionContent h3:nth-of-type(2) {
	padding-left: 115px;
}
.interactionContent .gradientButton {
	width: 250px;
	padding: 24px 0;
}

.topSection .sourcing {
	background: #202020;
	padding: 10px;
	color: white;
	text-align: center;
	font-family: B612 Mono;
	font-size: 37px;
	font-weight: 400;
	line-height: 45px;
	letter-spacing: 0em;
	z-index: 8;
	position: absolute;
	width: 100%;
	left: 0px;
	top: calc(50% - 100px);
}

.topSection .hideDemo {
	cursor: pointer;
	position: fixed;
	bottom: 50px;
	left: 32px;
	z-index: 999999;
}

.topSection .demo-content {
	height: 100vh;
	position: relative;
	z-index: 9999;
	display: flex;
	align-items: center;
}

.mt-64px {
	margin-top: 64px;
}

.topSection .demo-content h2 {
	font-family: var(--naming-font-alt-heading);
	font-size: 60px;
	font-weight: 500;
	line-height: 110%;
	letter-spacing: 0em;
	text-align: left;
	margin: 0px;
	max-width: 430px;
}

.topSection .demo-content h4 {
	font-family: var(--naming-font-alt-heading);
	font-size: 40px;
	font-weight: 500;
	line-height: 110%;
	letter-spacing: 0em;
	text-align: left;
	margin: 0;
	max-width: 510px;
}

.topSection .demo-content p {
	font-family: Noto Sans;
	font-size: 20px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
	margin-top: 0px;
}

.topSection .demo-content .buttons {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 50px;
	margin-top: 60px;
}

.topSection .demo-content .result-demo {
	overflow: hidden;
	position: fixed;
	height: 100%;
	max-height: 682px;
	width: 100%;
	max-width: 966px;
	z-index: 99999;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.backImageContent {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	padding-top: 10%;
	padding-left: 10%;
	z-index: -1;
}
.backImage {
	width: 100%;
	height: 100%;
}

.topSection .demo-content .result-demo h3 {
	font-family: var(--naming-font-alt-heading);
	font-size: 60px;
	font-weight: 570;
	line-height: 120%;
	letter-spacing: 0em;
	text-align: left;
	background: white;
	max-width: 500px;
	padding: 15px;
	margin: 25px 25px 10px;
}

.topSection .demo-content .result-demo p {
	font-family: Noto Sans;
	font-size: 16px;
	font-weight: 570;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	background: white;
	margin-top: 5px;
	max-width: 500px;
	padding: 15px;
	margin: 0 25px 25px;
}

.topSection .demo-content .gradientButton.tryAnotherButton {
	z-index: 999990;
	position: fixed;
	right: calc(50% - 600px);
	bottom: 50px;
}

/* Meet firmi ================================= */
.meetFirmi {
	width: 100%;
	position: relative;
	background-color: var(--Lippincott-Gray-8);
	color: white;
}
.firmiWrapper {
	display: flex;
	flex-wrap: wrap;
}
.firmiColumnOne {
	flex: 0 0 auto;
	width: 43%;
	text-align: right;
	display: flex;
	justify-content: center;
	align-items: center;
}
.firmiColumnOne > div {
	padding: 0 72px;
}
.firmiColumnOne.second {
	align-items: flex-start;
}
.firmiColumnOne img {
	max-width: 100%;
	height: auto;
	width: 482px;
}
.firmiColumnOne h2 {
	font-family: var(--naming-font-alt-heading);
	font-size: 60px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
	text-align: left;
}
.firmiColumnTwo {
	flex: 0 0 auto;
	width: 57%;
	background-color: #000;
	text-align: left;
}
.paddingTextOne {
	padding-top: 250px;
	padding-bottom: 100px;
}
.twoTextWrap {
	max-width: 638px;
	padding-left: 110px;
}
.twoTextWrap h2 {
	font-family: var(--naming-font-alt-heading);
	font-size: 100px;
	font-style: normal;
	font-weight: 500;
	line-height: 100%;
	margin: 0 0 100px;
}
.twoTextWrap p {
	font-family: "Noto Sans";
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0 0 75px;
}
.twoTextWrap h3 {
	font-family: var(--naming-font-alt-heading);
	font-size: 40px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%;
	margin: 0 0 75px;
}
.twoTextWrap ul {
	padding: revert;
	margin-bottom: 75px;
}
.twoTextWrap ul li {
	list-style: disc;
	font-family: var(--naming-font-alt-heading);
	font-size: 30px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}
.twoTextWrap ul li + li {
	margin-top: 32px;
}
.twoTextWrap img {
	max-width: 100%;
	height: auto;
}
.firmiDivider {
	width: 90%;
	margin: 0 auto;
	height: 1px;
	background-color: white;
	margin-bottom: 50px;
}
.videoContainer {
	cursor: pointer;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
}

.videoContainer video {
	width: 100%;
	height: auto;
}

.demo-container-desktop {
	position: sticky;
	top: 110px;
	height: 100vh;
	margin-top: 300px;
}

.demo-container-mobile {
	display: none;
}

.images-wrapp-mobile {
	display: none;
}
.images-wrapp-desktop {
	display: block;
}

/* Responsive ================================= */
@media screen and (max-width: 1280px) {
	.topSection {
		padding-bottom: 208px;
	}
	.topSection .contentWrapper {
		gap: 320px;
	}
	.heroNaming {
		background-size: 492px, 689px;
	}
	.pageTitle {
		font-size: 80px;
	}
	.topSection .textContent {
		width: 60%;
	}
	.topSection .floatingImage.verizonPhone {
		width: 463px;
	}
	.topSection .floatingImage.offJhonson {
		width: 420px;
		top: 616px;
	}
	.topSection .floatingImage.duracell {
		width: 316px;
		top: 1230px;
	}
	.topSection .floatingImage.sprite {
		width: 525px;
		top: 1453px;
		left: -160px;
	}
	.topSection .floatingImage.robotott {
		width: 375px;
		top: 2056px;
		left: -20px;
	}
	.topSection .floatingImage.dog3D {
		width: 221px;
		top: 2136px;
		left: -10px;
	}
	.topSection .floatingImage.demoImage {
		width: 396px;
		top: 2334px;
	}
	.topSection .floatingImage.guitar3D {
		width: 206px;
		top: 2442px;
	}
	.topSection .floatingImage.touros {
		width: 953px;
		top: 2496px;
		left: -110px;
	}
	.topSection .floatingImage.sphere {
		width: 481px;
		top: 2724px;
		left: 500px;
	}
	.regenerateBadge {
		padding: 25px 80px;
	}
	.topSection.showDemo .interactionContent {
		margin-top: 130px;
	}
	.interactionContent h3 {
		font-size: 180px;
	}
	.topSection .demo-content h2 {
		font-size: 52px;
	}
	.topSection .demo-content h4 {
		font-size: 32px;
		max-width: 410px;
	}
	.topSection.showDemo .floatingImage.demoImage {
		transform: rotate(5deg) scale(1.1) translate(-120px, 130px);
	}
	.topSection .demo-content .gradientButton.tryAnotherButton {
		right: 50px;
		bottom: 50px;
	}
	.firmiColumnOne > div {
		padding: 0 32px;
	}
	.twoTextWrap {
		padding-left: 32px;
		padding-right: 32px;
	}
	.twoTextWrap h2 {
		font-size: 80px;
	}
	.twoTextWrap p {
		font-size: 18px;
	}
	.twoTextWrap h3 {
		font-size: 32px;
	}
	.twoTextWrap ul li {
		font-size: 24px;
	}
}

@media screen and (max-width: 980px) {
	.interactionContent .gradientButton {
		font-size: 16px;
		padding: 0;
		width: 180px;
		height: 60px;
	}
}
@media screen and (max-width: 950px) {
	.topSection .floatingImage.verizonPhone {
		transform: rotate(10deg);
		width: 430px;
	}

	.topSection .floatingImage.offJhonson {
		transform: rotate(-10deg);
		width: 400px;
		top: 446px;
		left: -130px;
	}

	.topSection .floatingImage.duracell {
		transform: rotate(25deg);
		width: 300px;
		top: 940px;
	}

	.topSection .floatingImage.sprite {
		transform: rotate(-15deg);
		width: 400px;
		top: 1113px;
		left: -70px;
	}

	.topSection .floatingImage.dog3D {
		/* width: 116px; */
		top: 2286px;
		left: -13px;
	}

	.topSection .floatingImage.robotott {
		width: 300px;
		top: 2238px;
		left: -27px;
	}

	.topSection .floatingImage.demoImage {
		left: 29px;
	}

	.topSection .floatingImage.touros {
		width: 750px;
		left: -50px;
	}

	.topSection .floatingImage.sphere {
		width: 450px;
		top: 85%;
		left: 430px;
	}

	.topSection .floatingImage.guitar3D {
		transform: rotate(36deg);
		left: 31px;
	}

	.interactionContent h3 {
		font-size: 120px;
	}

	.paddingTextOne {
		padding-top: 130px;
	}
}

@media screen and (max-width: 768px) {
	.pageTitle {
		font-size: 60px;
	}

	.namingParagraph {
		font-size: 14px;
	}

	.namingTitle40px {
		font-size: 24px;
	}

	.namingQuote li {
		font-size: 18px;
	}

	.topSection {
		padding-bottom: 50px;
	}

	.namingParagraph.capitalized p::first-letter {
		font-size: 7rem;
		line-height: 0.6;
	}

	.topSection .floatingImage.verizonPhone {
		width: 330px;
		top: 70px;
		left: -65px;
		z-index: 10;
		transform: rotate(10deg);
	}

	.topSection .floatingImage.offJhonson {
		width: 300px;
		top: 326px;
		left: -100px;
		transform: rotate(-10deg);
	}

	.topSection .floatingImage.duracell {
		width: 300px;
		top: 662px;
		left: -90px;
		transform: rotate(40deg);
	}

	.topSection .floatingImage.sprite {
		width: 260px;
		top: 809px;
		left: -70px;
		transform: rotate(-15deg);
	}

	.topSection .interactionContent {
		padding: 0 9px;
	}

	.interactionContent .gradientButton {
		font-size: 14px;
		padding: 0;
		width: 150px;
		height: 48px;
	}

	.interactionContent h3 {
		font-size: 90px;
	}

	.interactionContent h3:nth-of-type(2) {
		padding-left: 60px;
	}

	.topSection .floatingImage.dog3D {
		width: 150px;
		top: 2286px;
		left: -30px;
	}

	.topSection .floatingImage.robotott {
		width: 220px;
		top: 2238px;
		left: 0px;
	}

	.topSection .floatingImage.demoImage {
		width: 220px;
		top: 2354px;
		left: 15px;
	}

	.topSection .floatingImage.touros {
		width: 421px;
		top: 2416px;
		left: -6px;
	}

	.topSection .floatingImage.sphere {
		width: 350px;
		top: 85%;
		left: 280px;
	}

	.topSection .floatingImage.guitar3D {
		transform: rotate(0deg);
		width: 160px;
		left: -60px;
		top: 2314px;
	}

	.paddingTextOne {
		padding-top: 100px;
	}

	.twoTextWrap h2 {
		font-size: 60px;
		margin-bottom: 48px;
	}

	.twoTextWrap p {
		font-size: 14px;
	}

	.twoTextWrap p,
	.twoTextWrap h3 {
		margin-bottom: 64px;
	}

	.twoTextWrap h3 {
		font-size: 24px;
	}

	.twoTextWrap ul li {
		font-size: 18px;
	}

	.firmiColumnOne h2 {
		font-size: 30px;
		margin: 0;
	}

	.topSection.showDemo .interactionContent {
		margin-top: 250px;
	}

	.topSection .demo-content {
		align-items: flex-start;
	}

	.topSection .demo-content .result-demo {
		max-width: 90%;
		max-height: 90%;
	}

	.topSection .demo-content h2 {
		font-size: 36px;
	}

	.topSection .demo-content p {
		font-size: 16px;
		max-width: 240px;
	}

	.topSection .demo-content .result-demo h3 {
		font-size: 44px;
		margin: 0 20px 10px 0;
	}

	.topSection .demo-content .result-demo p {
		margin: 0 20px 20px 0;
	}

	.topSection .demo-content h4 {
		font-size: 24px;
		max-width: 320px;
	}

	.topSection .demo-content .buttons {
		margin-top: 24px;
		gap: 20px;
	}

	.topSection.showDemo .floatingImage.demoImage {
		transform: rotate(0deg) scale(2.3) translate(0px, 49px);
	}

	.topSection .hideDemo {
		transform: scale(0.8);
		bottom: 32px;
		left: 32px;
	}

	.topSection .demo-content .gradientButton.tryAnotherButton {
		right: 32px;
		bottom: 42px;
	}
}

@media screen and (max-width: 627px) {
	.interactionContent .gradientButton {
		font-size: 11px;
		padding: 0;
		width: 110px;
		height: 38px;
	}
}

@media all and (max-width: 500px) {
	.topSection.showDemo .interactionContent {
		margin-top: 40px;
	}

	.topSection .demo-content {
		align-items: flex-start;
	}

	.topSection {
		padding-bottom: 470px;
	}

	.contentWrapper {
		padding-left: 15px;
		padding-right: 15px;
		width: calc(100% - 30px);
	}

	.topSection .textContent {
		padding-top: 60px;
		gap: 64px;
		height: auto !important;
		width: 70%;
	}

	.namingParagraph {
		font-size: 18px;
	}

	.namingParagraph.capitalized p::first-letter {
		/* font-size: 80px;
    line-height: 37px;
    margin-right: 6px; */
		font-size: 8.4rem;
		line-height: 0.62;
	}

	.namingTitle40px {
		font-size: 26px;
	}

	.namingQuote li {
		font-size: 24px;
	}

	.topSection .demo-content .result-demo {
		max-width: 90%;
		max-height: 90%;
	}

	.firmiWrapper {
		flex-direction: column;
	}

	.firmiColumnOne,
	.firmiColumnTwo {
		width: 100%;
	}

	.firmiColumnOne > div {
		padding: 150px 32px;
	}

	.twoTextWrap {
		max-width: 100%;
		padding: 150px 64px;
	}

	.contentWrapperMobile {
		display: flex !important;
	}

	.contentWrapperDesktop {
		display: none !important;
	}

	.heroNaming {
		background-size: 412px, 449px;
	}

	.heroNaming .tagline {
		padding: 20px;
	}

	.heroNaming .tagline img {
		width: 160px;
	}

	.heroNaming .contentWrapper {
		margin-top: auto;
	}

	.pageTitle {
		font-size: 40px;
		margin: 0;
	}

	.topSection .contentWrapper {
		gap: 200px;
	}

	.topSection .floatingImage.verizonPhone {
		width: 223px;
		top: 70px;
		left: -85px;
		z-index: 10;
		transform: rotate(10deg);
	}

	.topSection .floatingImage.offJhonson {
		width: 220px;
		top: 326px;
		left: -100px;
		transform: rotate(-10deg);
	}

	.topSection .floatingImage.duracell {
		width: 156px;
		top: 640px;
		left: -40px;
		transform: rotate(25deg);
	}

	.topSection .floatingImage.sprite {
		width: 195px;
		top: 770px;
		left: -70px;
		z-index: 8;
		transform: rotate(-15deg);
	}

	.regenerateBadge {
		font-size: 15px;
		max-width: 230px;
		padding: 32px;
	}

	.topSection .interactionContent {
		padding: 0 9px;
	}

	.interactionContent .gradientButton {
		font-size: 14px;
		padding: 0;
		width: 150px;
		height: 48px;
	}

	.interactionContent h3 {
		font-size: 120px;
	}

	.interactionContent h3:nth-of-type(2) {
		padding-left: 0;
	}

	.topSection .floatingImage.dog3D {
		width: 116px;
		top: 2286px;
		left: -13px;
	}

	.topSection .floatingImage.robotott {
		width: 190px;
		top: 2238px;
		left: 27px;
	}

	.topSection .floatingImage.demoImage {
		width: 190px;
		top: 2354px;
		left: 29px;
	}

	.topSection .floatingImage.touros {
		width: 421px;
		top: 2416px;
		left: -6px;
	}

	.topSection .floatingImage.sphere {
		display: none;
	}

	.topSection .floatingImage.guitar3D {
		transform: rotate(36deg);
		width: 126px;
		left: 31px;
		top: 2314px;
	}

	.mt-64px {
		margin-top: 32px;
	}

	.topSection .demo-content h2 {
		font-size: 36px;
	}

	.topSection .demo-content p {
		font-size: 16px;
		max-width: 240px;
	}

	.topSection .demo-content .result-demo h3 {
		font-size: 44px;
		margin: 0 20px 10px 0;
	}

	.topSection .demo-content .result-demo p {
		margin: 0 20px 20px 0;
	}

	.topSection .demo-content h4 {
		font-size: 24px;
		max-width: 320px;
	}

	.topSection .demo-content .buttons {
		margin-top: 24px;
		gap: 20px;
	}

	.topSection.showDemo .floatingImage.demoImage {
		transform: rotate(0deg) scale(2.3) translate(0px, 49px);
	}

	.topSection .hideDemo {
		transform: scale(0.8);
		bottom: 32px;
		left: 32px;
	}

	.topSection .demo-content .gradientButton.tryAnotherButton {
		right: 32px;
		bottom: 42px;
	}

	.firmiColumnOne > div {
		padding: 100px 32px;
	}

	.twoTextWrap {
		padding: 64px 32px;
	}

	.twoTextWrap h2 {
		font-size: 70px;
		margin-bottom: 48px;
	}

	.twoTextWrap p,
	.twoTextWrap h3 {
		margin-bottom: 64px;
	}

	.twoTextWrap h3 {
		font-size: 24px;
	}

	.firmiColumnOne h2 {
		font-size: 48px;
		margin: 0;
	}

	/* DEMO */
	.demo-container-desktop {
		display: none;
	}

	.demo-container-mobile {
		display: block;
	}

	.images-wrapp-desktop {
		display: none;
	}
}

@media all and (max-width: 470px) {
	.topSection .floatingImage.dog3D {
		top: 2336px;
	}

	.topSection .floatingImage.robotott {
		top: 2288px;
	}

	.topSection .floatingImage.demoImage {
		top: 2404px;
	}

	.topSection .floatingImage.touros {
		top: 2466px;
	}

	.topSection .floatingImage.guitar3D {
		top: 2364px;
	}
}
@media all and (max-width: 450px) {
	.topSection .floatingImage.dog3D {
		top: 2436px;
	}

	.topSection .floatingImage.robotott {
		top: 2388px;
	}

	.topSection .floatingImage.demoImage {
		top: 2504px;
	}

	.topSection .floatingImage.touros {
		top: 2566px;
	}

	.topSection .floatingImage.guitar3D {
		top: 2464px;
	}
}

/* just for iphone --------------- */
@media all and (max-width: 430px) {
	.topSection {
		padding-bottom: 500px;
	}

	.topSection.showDemo .interactionContent {
		margin-top: 40px;
	}

	.topSection .floatingImage.dog3D {
		top: 2486px;
	}

	.topSection .floatingImage.robotott {
		top: 2438px;
	}

	.topSection .floatingImage.demoImage {
		top: 2554px;
	}

	.topSection .floatingImage.touros {
		top: 2616px;
	}

	.topSection .floatingImage.guitar3D {
		top: 2514px;
	}
}

@media all and (max-width: 410px) {
	.topSection .floatingImage.dog3D {
		top: 2536px;
	}

	.topSection .floatingImage.robotott {
		top: 2488px;
	}

	.topSection .floatingImage.demoImage {
		top: 2604px;
	}

	.topSection .floatingImage.touros {
		top: 2666px;
	}

	.topSection .floatingImage.guitar3D {
		top: 2564px;
	}
}

@media all and (max-width: 400px) {
	.breadCrumb span {
		font-size: 14px;
	}
	.hastag {
		font-size: 18px;
	}
	.topSection .textContent {
		width: 75%;
	}
	.namingParagraph {
		font-size: 17px;
	}
	.namingTitle40px {
		font-size: 22px;
	}
	.topSection {
		padding-bottom: 400px;
	}
	.topSection .textContent {
		gap: 48px;
	}
	.namingQuote {
		padding-left: 30px;
	}
	.namingQuote::before {
		width: 5px;
	}
	.namingQuote li {
		font-size: 22px;
	}
	.topSection .floatingImage.verizonPhone {
		width: 190px;
	}
	.topSection .floatingImage.offJhonson {
		width: 180px;
		top: 298px;
	}
	.topSection .floatingImage.duracell {
		width: 116px;
		top: 532px;
	}
	.topSection .floatingImage.sprite {
		width: 150px;
		top: 632px;
	}
	.topSection .floatingImage. {
		width: 155px;
		top: 685px;
	}
	.topSection .floatingImage.dog3D {
		width: 115px;
		top: 2166px;
	}
	.topSection .floatingImage.robotott {
		width: 170px;
		top: 2118px;
	}
	.topSection .floatingImage.demoImage {
		width: 180px;
		top: 2224px;
		left: 22px;
	}
	.topSection .floatingImage.guitar3D {
		width: 117px;
		left: 20px;
		top: 2190px;
	}
	.topSection .floatingImage.touros {
		width: 401px;
		top: 2283px;
	}

	.topSection .demo-content .result-demo h3 {
		font-size: 34px;
	}
	.topSection.showDemo .interactionContent {
		margin-top: 40px;
	}
	.topSection .demo-content h2 {
		font-size: 32px;
	}
	.topSection.showDemo .floatingImage.demoImage {
		transform: rotate(0deg) scale(2) translate(0px, 29px);
	}
	.topSection .hideDemo {
		bottom: 24px;
		left: 24px;
	}
	.topSection .demo-content .gradientButton.tryAnotherButton {
		right: 24px;
		bottom: 36px;
	}

	.twoTextWrap h2 {
		font-size: 60px;
	}
	.firmiColumnOne h2 {
		font-size: 44px;
	}
	.twoTextWrap ul li {
		font-size: 22px;
	}
}
