.welcomeHeaderWrapper {
	position: fixed;
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 840px;
	margin: auto;
	transform: translateX(-50%) translateY(-50%);
	top: 50%;
	left: 50%;
	text-align: center;
	padding-left: 32px;
	padding-right: 32px;
}

.welcomeHeaderWrapper h1,
.welcomeHeaderWrapper h2 {
	font-family: "Victor Serif Regular", sans-serif;
	font-weight: 500;
	color: white;
}

.welcomeHeaderWrapper h1 {
	font-size: 100px;
	margin: 0 0 24px;
}

.welcomeHeaderWrapper h2 {
	margin: 0 0 45px;
	font-size: 30px;
}

.welcomeHeaderWrapper p {
	margin: 0;
	font-family: "Noto Sans";
	font-size: 20px;
	color: white;
	width: 100%;
	max-width: 650px;
	margin: 0 auto;
}

@media screen and (max-width: 768px) and (min-width: 481px) {
	.welcomeHeaderWrapper h1 {
		font-size: 60px;
		margin-bottom: 0;
	}
	.welcomeHeaderWrapper h2 {
		font-size: 18px;
		max-width: 400px;
		margin: 0 auto;
		margin-bottom: 14px;
	}
	.welcomeHeaderWrapper p {
		font-size: 12px;
		max-width: 300px;
		margin: 0 auto;
	}
}

@media screen and (max-width: 480px) {
	.welcomeHeaderWrapper {
		max-width: 300px;
	}
	.welcomeHeaderWrapper h1 {
		font-size: 60px;
	}
	.welcomeHeaderWrapper h2 {
		font-size: 20px;
		margin-bottom: 80px;
	}
	.welcomeHeaderWrapper p {
		font-size: 16px;
	}
}
@media screen and (max-width: 400px) {
	.welcomeHeaderWrapper h1 {
		font-size: 52px;
	}
}
